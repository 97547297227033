import React, { Component } from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import { format, parseISO } from 'date-fns';
import ReactLoading from 'react-loading';
import Select from 'react-select';
import Modal from 'react-responsive-modal';
import debounce from 'lodash.debounce';
import AsyncSelect from 'react-select/async';
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/fontawesome-free-regular';
import RsaLogo from '../../img/rsa_logo.png';
import OsagoIntegrationAnswers from './Osago/OsagoIntegrationAnswers';
import OsagoForm from './Osago/OsagoForm';
import KaskoForm from './Kasko/KaskoForm';
import OSAGOProlongationForm from './Osago/OSAGOProlongationForm';
import {
  customFetch,
  customSelectStyle,
  loadSuggestions,
} from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import PolicyInfo from '../Utils/UiComponents/PolicyInfo';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

const FileSaver = require('file-saver');

class PolicyContainer extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      openModalCopy: false,
      reasonForChangeCost: null,
      newCost: null,
      newAgent: null,
      reasonForCancel: { label: 'По ошибке', value: 'ПоОшибке' },
      reasonForNullify: null,
      modalSureIsOpen: false,
      cancelingPolicyFlag: false,
      policy: {},
      activeTab: '1',
      errorNotAllowed: false,
      errorPolicyNotExist: false,
      egarantLoading: false,
      integrationAnswersLoading: false,
      policyInfo: {
        chain: false,
        erp_status: false,
        bitrix24_lead_id: '',
        integration: '',
        commission: 0,
        createdDate: '',
        updatedDate: '',
        policyStatus: '',
        widgetId: 0,
        agentFIO: '',
        agent_id: 0,
        profit: 0,
        const: 0,
        prolongation_parent_id: '',
        typeDeclaration: null,
        middleOfficeAgent: null,
        digital_broker_id: null,
      },
    };
  }

  componentDidMount() {
    const { location, match, setTour } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
    if (match.params.id) {
      this.loadPolicy(match.params.id);
    } else {
      setTour('osago_tour');
    }
  }

  loadPolicy = (formId) => new Promise((resolve) => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${formId}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((policy) => {
        if (policy.error) {
          this.setState({ errorNotAllowed: true });
        } else if (policy.id) {
          let policyStatus;
          const { setTour } = this.props;
          switch (policy.status) {
            case 'complete':
              policyStatus = 'Оплачен';
              break;
            case 'ordered':
              policyStatus = 'Заказ';
              break;
            case 'pending':
              policyStatus = 'Редактирование';
              break;
            case 'processing':
              policyStatus = 'Обрабатывается';
              break;
            case 'calculated':
              policyStatus = 'Расчет';
              break;
            case 'need_help':
              policyStatus = 'Нужна помощь';
              break;
            case 'canceled':
              policyStatus = 'Аннулирован';
              break;
            default:
              policyStatus = '';
              break;
          }
          this.setState({
            policy,
            policyInfo: {
              erp_status: policy.erp_status,
              bitrix24_lead_id: policy.bitrix24_lead_id,
              integration: policy.type_integration,
              commission: policy.commission,
              createdDate: format(parseISO(policy.created_at), 'dd.MM.yyyy'),
              updatedDate: format(parseISO(policy.updated_at), 'dd.MM.yyyy'),
              policyStatus,
              widgetId: policy.widget_id != null ? policy.widget_id : 0,
              agentFIO: `${policy.agent.last_name} ${policy.agent.first_name} ${policy.agent.middle_name}`,
              agent_id: policy.agentId,
              profit: policy.profit,
              cost: policy.cost,
              prolongation_parent_id: policy.prolongation_parent_id,
              chain: policy.chain ? policy.chain : false,
              linkPdf: [policy.link],
              linkArchive: policy.archive,
              status: policy.status,
              company: policy.company,
              insuranceId: policy.insurance_id,
              orderDate: policy.order_date ? format(parseISO(policy.order_date), 'dd.MM.yyyy') : null,
              typeDeclaration: policy.type_declaration ? policy.type_declaration : null,
              middleOfficeAgent: policy.middle_office_agent ? policy.middle_office_agent : null,
              digital_broker_id: policy.digital_broker_id ? policy.digital_broker_id : null,
            },
          }, () => {
            setTour('osago_tour');
            resolve(policy.id);
          });
        }
      }, () => {
        this.setState({ errorPolicyNotExist: true });
      })
      .catch(() => {
        const { showModalInfo } = this.context;
        showModalInfo('Ошибка при открытии черновика', 'error');
      });
  });

  copyForm = (e) => {
    this.child.current.copyForm(e);
  }

  convertPolicyToDkp = (e) => {
    this.child.current.convertPolicyToDkp(e);
  }

  checkPayment = (e) => {
    e.preventDefault();
    const { policy } = this.state;
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policy.id}/check_payment`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else {
          showModalInfo(response.message);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  makeRequestForSupport = (e) => {
    e.preventDefault();
    this.child.current.makeRequestForSupport(e);
  };

  getApiLogs = (e) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    const { policy } = this.state;
    const { policyType } = this.props;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policyType}/${policy.id}/get-draft-logs`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.headers.get('Content-Type').includes('json')) {
          return response.json();
        }
        return response.blob();
      })
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else {
          FileSaver.saveAs(response, 'logs.zip');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  addToErp = () => {
    this.setState({ cancelingPolicyFlag: true });
    const { policy } = this.state;
    const { policyType } = this.props;
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/${policyType}/${policy.id}/addToErp`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error);
        } else {
          showModalInfo(response.message);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        this.setState({
          cancelingPolicyFlag: false,
          modalSureIsOpen: false,
        });
      });
  };

  editPolicy = (e) => {
    e.preventDefault();
    this.setState({ cancelingPolicyFlag: true });
    const {
      policy, modalSureIsOpen, reasonForCancel, reasonForNullify, reasonForChangeCost, newCost, newAgent,
    } = this.state;
    const { policyType } = this.props;
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    let reason;
    switch (modalSureIsOpen) {
      case 'cancel':
        reason = reasonForCancel;
        break;
      case 'changeCost':
        reason = reasonForChangeCost;
        break;
      case 'nullify':
        reason = reasonForNullify;
        break;
      default:
        reason = '';
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policy.id}/${modalSureIsOpen}`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reason,
        updateInErp: true,
        product: policyType,
        newCost,
        newAgent,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error);
        } else {
          this.loadPolicy(policy.id);
          showModalInfo(response.message);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        this.setState({
          cancelingPolicyFlag: false,
          modalSureIsOpen: false,
        });
      });
  };

  goToWidget = (e) => {
    const { policyInfo } = this.state;
    const { history } = this.props;
    e.preventDefault();
    history.push(`/widgets/${policyInfo.widgetId}`);
  };

  goToAgent = (e, id) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(`/agent/${id}`);
  }

  goToPolicy = (e) => {
    const { policyInfo } = this.state;
    const { history } = this.props;
    e.preventDefault();
    history.push(`/osago/${policyInfo.prolongation_parent_id}`);
    this.loadPolicy(policyInfo.prolongation_parent_id);
  }

  onCloseModalSure = () => {
    this.setState({ modalSureIsOpen: false });
  };

  onOpenModalSure = (e, type) => {
    e.preventDefault();
    this.setState({ modalSureIsOpen: type });
  };

  copyDraft = () => {
    const { policy } = this.state;
    const { policyType } = this.props;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/copy/${policy.id}`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const { history } = this.props;
        this.loadPolicy(response);
        history.push(`/${policyType}/${response}`);
        this.setState({ openModalCopy: true });
      });
  };

  onCloseModalCopy = () => {
    this.setState({ openModalCopy: false });
  };

  setIntegrationAnswersLoading = (flag) => {
    this.setState({ integrationAnswersLoading: flag });
  };

  handleChangeInput = (e, field) => {
    this.setState({ [field]: e });
  };

  toggleTab(tab) {
    const { activeTab } = this.state;
    const { match } = this.props;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    if (match.params.id) {
      this.loadPolicy(match.params.id);
    }
    window.history.replaceState(null, null, `?activeTab=${tab}`);
  }

  render() {
    const {
      activeTab,
      policy,
      errorNotAllowed,
      errorPolicyNotExist,
      policyInfo,
      cancelingPolicyFlag,
      modalSureIsOpen,
      egarantLoading,
      integrationAnswersLoading,
      reasonForCancel,
      openModalCopy,
      reasonForChangeCost,
      newCost,
      newAgent,
    } = this.state;
    const {
      match,
      history,
      location,
      policyType,
      userInfo,
    } = this.props;

    return (
      <div className="position-relative">
        {((policy.policy_type && policyType === policy.policy_type.code) || !match.params.id) ? (
          <>
            <Modal
              classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
              closeIconSize={16}
              open={openModalCopy}
              onClose={this.onCloseModalCopy}
              center
            >
              <h4>Черновик скопирован</h4>
            </Modal>
            <PolicyInfo
              product={policyType === 'osago' ? 'osago' : 'kasko'}
              policy={policy}
              policyInfo={policyInfo}
              copyForm={this.copyForm}
              checkPayment={this.checkPayment}
              makeRequestForSupport={this.makeRequestForSupport}
              getApiLogs={this.getApiLogs}
              onOpenModalSure={this.onOpenModalSure}
              goToAgent={this.goToAgent}
              goToWidget={this.goToWidget}
              goToPolicy={this.goToPolicy}
              convertPolicyToDkp={this.convertPolicyToDkp}
              history={history}
            />
            {errorNotAllowed && (
              <div className="row mb-3">
                <div className="col-lg-3">
                  <div className="error_block">Недостаточно прав</div>
                </div>
              </div>
            )}
            {errorPolicyNotExist && (
              <div className="row mb-3">
                <div className="col-lg-3">
                  <div className="error_block">Данный полис не существует</div>
                </div>
              </div>
            )}
            <TabNavList>
              <TabNavItem
                active={activeTab === '1'}
                onClick={() => { this.toggleTab('1'); }}
                text="Новый полис"
              />
              {policyType === 'osago' && policy.id ? (
                <TabNavItem
                  active={activeTab === '2'}
                  onClick={() => { this.toggleTab('2'); }}
                  text="Предыдущие результаты"
                />
              ) : null}
              {policyType === 'osago' && !policy.id ? (
                <div className="d-flex align-items-baseline">
                  <TabNavItem
                    active={activeTab === '3'}
                    onClick={() => { this.toggleTab('3'); }}
                    text="Быстрая пролонгация полиса"
                  />
                  <Tippy
                    className="tippy-custom"
                    arrow={false}
                    offset={[0, 5]}
                    content={<div style={{ maxWidth: 450 }}>Возможны 2 варианта: 1.Полис готовится впервые для новой ипотечной сделки - в таком случае дата начала действия полиса должна быть равна дате подписания кредитного договора 2.Пролонгация (продление) для клиента с уже действующей ипотекой - дата начала нового полиса - это день, следующий за окончанием предыдущего полиса.</div>}
                    hideOnClick={false}
                  >
                    <div className="d-inline-block">
                      <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                    </div>
                  </Tippy>
                </div>
              ) : null}
            </TabNavList>
            <TabContent animation="false" activeTab={activeTab}>
              <TabPane className="d-table" tabId="1">
                {activeTab === '1' ? (
                  <>
                    {egarantLoading ? (
                      <ReactLoading className="loading-circle ml-auto mr-auto mt-2" type="spin" height={70} width={70} />
                    ) : null}
                    {policyType === 'osago'
                      ? (
                        <OsagoForm
                          RsaLogo={RsaLogo}
                          ref={activeTab === '1' ? this.child : undefined}
                          loadPolicy={this.loadPolicy}
                          copyDraft={this.copyDraft}
                          policy={policy}
                          policyType={policyType}
                          match={match}
                          history={history}
                          location={location}
                          userInfo={userInfo}
                        />
                      )
                      : null}
                    {policyType === 'kasko'
                      ? (
                        <KaskoForm
                          RsaLogo={RsaLogo}
                          ref={activeTab === '1' ? this.child : undefined}
                          loadPolicy={this.loadPolicy}
                          copyDraft={this.copyDraft}
                          policy={policy}
                          match={match}
                          history={history}
                          location={location}
                        />
                      )
                      : null}
                    {policyType === 'accident' ? (
                      <OsagoForm
                        RsaLogo={RsaLogo}
                        ref={activeTab === '1' ? this.child : undefined}
                        loadPolicy={this.loadPolicy}
                        copyDraft={this.copyDraft}
                        policy={policy}
                        policyType={policyType}
                        match={match}
                        history={history}
                        location={location}
                        userInfo={userInfo}
                      />
                    ) : null}
                  </>
                ) : null }
              </TabPane>
              {policyType === 'osago' ? (
                <TabPane tabId="2">
                  { activeTab === '2' ? (
                    <>
                      {egarantLoading ? (
                        <ReactLoading className="loading-circle ml-auto mr-auto mt-2" type="spin" height={70} width={70} />
                      ) : null}
                      {!egarantLoading && integrationAnswersLoading ? (
                        <ReactLoading className="loading-circle ml-auto mr-auto mt-2" type="spin" height={70} width={70} />
                      ) : null}
                      <OsagoIntegrationAnswers
                        ref={activeTab === '2' ? this.child : undefined}
                        copyDraft={this.copyDraft}
                        match={match}
                        setIntegrationAnswersLoading={this.setIntegrationAnswersLoading}
                        integrationAnswersLoading={integrationAnswersLoading}
                      />
                    </>
                  )
                    : null }
                </TabPane>
              ) : null}
              {policyType === 'osago' ? (
                <TabPane tabId="3">
                  { activeTab === '3' ? (
                    <OSAGOProlongationForm history={history} />
                  ) : null }
                </TabPane>
              ) : null}
            </TabContent>
            <Modal
              classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
              closeIconSize={16}
              open={modalSureIsOpen}
              onClose={this.onCloseModalSure}
              center
            >
              <h4>Вы уверены?</h4>
              <form onSubmit={(e) => this.editPolicy(e)}>
                {modalSureIsOpen === 'changeAgent' ? (
                  <div className="form-group row">
                    <div className="col-md-12">
                      <label>Укажите нового агента</label>
                      <AsyncSelect
                        styles={customSelectStyle()}
                        name="newAgent"
                        placeholder="Укажите агента"
                        classNamePrefix="react-select"
                        inputId="newAgent"
                        value={newAgent || ''}
                        onChange={(e) => this.handleChangeInput(e, 'newAgent')}
                        className="form-control-custom"
                        loadingMessage={() => 'Загрузка'}
                        noOptionsMessage={() => 'Не найдено'}
                        loadOptions={debounce(loadSuggestions(''), 500)}
                      />
                    </div>
                  </div>
                ) : null}
                {modalSureIsOpen === 'cancel' ? (
                  <div className="form-group row">
                    <div className="col-md-12">
                      <label>Укажите причину аннулирования</label>
                      <Select
                        styles={customSelectStyle()}
                        name="reasonForCancel"
                        placeholder="Укажите причину"
                        classNamePrefix="react-select"
                        inputId="reasonForCancel"
                        value={reasonForCancel}
                        onChange={(e) => this.handleChangeInput(e, 'reasonForCancel')}
                        className="form-control-custom"
                        options={[
                          { label: 'По ошибке', value: 'ПоОшибке' },
                          { label: 'Отказ клиента', value: 'ОтказКлиента' },
                        ]}
                        noOptionsMessage={() => 'Не найдено'}
                      />
                    </div>
                  </div>
                ) : null}
                {modalSureIsOpen === 'changeCost' ? (
                  <div className="form-group row">
                    <div className="col-md-12">
                      <label>Укажите причину изменения</label>
                      <input
                        style={customSelectStyle()}
                        className="form-control"
                        required
                        value={reasonForChangeCost}
                        type="text"
                        id="reasonForChangeCost"
                        name="reasonForChangeCost"
                        onChange={(e) => this.handleChangeInput(e.target.value, 'reasonForChangeCost')}
                      />
                    </div>
                    <div className="col-md-12">
                      <label>Укажите СП</label>
                      <input
                        className="form-control"
                        required
                        value={newCost}
                        type="number"
                        id="newCost"
                        step="0.01"
                        name="newCost"
                        onChange={(e) => this.handleChangeInput(e.target.value, 'newCost')}
                      />
                    </div>
                  </div>
                ) : null}
                {modalSureIsOpen === 'add'
                  ? (<button disabled={cancelingPolicyFlag} className="btn btn-success mr-2" type="button" onClick={() => this.addToErp()}>Да</button>)
                  : (<button disabled={cancelingPolicyFlag} className="btn btn-success mr-2" type="submit">Да</button>)}
                <button disabled={cancelingPolicyFlag} type="button" className="btn btn-secondary" onClick={this.onCloseModalSure}>Нет</button>
                {cancelingPolicyFlag && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
              </form>
            </Modal>
          </>
        ) : null}
      </div>
    );
  }
}

PolicyContainer.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(PolicyContainer);
