import React, {
  forwardRef, useState, useContext, useEffect, useMemo,
} from 'react';
import classnames from 'classnames';
import ReactDatePicker from 'react-datepicker';
import ReactInputMask from 'react-input-mask';
import { addDays, format, parseISO } from 'date-fns';
import ReactLoading from 'react-loading';
import { ru } from 'date-fns/locale';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import {
  customFetch, customSelectStyle, loadSuggestions,
} from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import getCompanies from '../Utils/InsurancesCompaniesStyle';
import getBanks from '../Utils/ClassifiersLoaders';

function Stocks() {
  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

  const products = [
    { label: 'Имущество', value: 'mortgage' },
    { label: 'Жизнь', value: 'mortgageLife' },
    { label: 'Жизнь и имущество', value: 'mortgageComplex' },
    { label: 'ОСАГО', value: 'osago' },
  ];

  const type = [
    { label: 'Рубли', value: 'number' },
    /*{ label: 'Процент', value: 'procent' },*/
  ];

  const [description, setDescription] = useState('');
  const [number, setNumber] = useState('');
  const [banksOption, setBanksOption] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [limitInsurance, setLimitInsurance] = useState('');
  const [limitKv, setLimitKv] = useState('');
  const [currentCompany, setCurrentCompany] = useState(null);
  const [currentRisk, setCurrentRisk] = useState(products[0]);
  const [currentBank, setCurrentBank] = useState(null);
  const [typeValue, setTypeValue] = useState(type[0]);
  const [endDate, setEndDate] = useState('');
  const [forWidget, setForWidget] = useState(true);
  const [stocksOption, setStocksOption] = useState([]);
  const [activeId, setActiveId] = useState(0);
  const [agentsId, setAgentsId] = useState([]);
  const [load, setLoad] = useState(false);
  const [indexLoad, setIndexLoad] = useState(false);

  const customStyles = useMemo(() => customSelectStyle(), []);

  const { showModalInfo } = useContext(ModalMessagesContext);

  const changeOptions = (item) => {
    setStocksOption((stock) => [...stock, {
      label: `${item.description} ${item.number} рублей (${item.insurance_code} - ${item.bank_code} - ${item.risk}) ${item.for_widget ? 'Виджет' : ''}`,
      value: item.id,
      bank_code: item.bank_code,
      description: item.description,
      end_date: item.end_date,
      for_widget: item.for_widget,
      insurance_code: item.insurance_code,
      limit_insurance: item.limit_insurance,
      limit_kv: item.limit_kv,
      number: item.number,
      risk: item.risk,
      type: item.type,
      agents: item.agents,
    }]);
  };

  const changeData = (id) => {
    if (id) {
      setActiveId(id);
      console.log(banksOption);
      console.log(companies);
      console.log(stocksOption);
      stocksOption.find((item) => {
        if (item.value === id) {
          setDescription(item.description);
          setEndDate(item.end_date);
          setForWidget(!!item.for_widget);
          setLimitInsurance(item.limit_insurance);
          setLimitKv(item.limit_kv);
          setNumber(item.number);
          products.find((product) => product.value === item.risk && setCurrentRisk(product));
          type.find((value) => value.value === item.type && setTypeValue(value));
          banksOption.find((bank) => bank.value === item.bank_code && setCurrentBank(bank));
          companies.find((company) => company.value === item.insurance_code && setCurrentCompany(company));
          setAgentsId(item.agents);
        }
      });
    } else {
      setActiveId(id);
      setDescription('');
      setEndDate('');
      setForWidget('');
      setLimitInsurance('');
      setLimitKv('');
      setNumber('');
      setCurrentBank(null);
      setTypeValue(type[0]);
      setCurrentRisk(products[0]);
      setCurrentCompany(null);
      setAgentsId(null);
    }
  };

  const updateStock = () => {
    setLoad(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/updateStock/${activeId}`, {
      method: 'put',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        number,
        description,
        type: typeValue.value,
        end_date: endDate,
        for_widget: forWidget,
        insurance_code: currentCompany.value,
        risk: currentRisk.value,
        bank_code: currentBank.value,
        limit_kv: limitKv,
        limit_insurance: limitInsurance,
        agents: agentsId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setStocksOption([]);
          Array.isArray(response) && response.map((item) => {
            changeOptions(item);
          });
          showModalInfo('Условия акции изменены');
          changeData(0);
        } else {
          showModalInfo('Не удалось изменить условия акции');
        }
      })
      .catch(() => {
        showModalInfo('Не удалось изменить условия акции');
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const stockCreate = () => {
    setLoad(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/createStock`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        number,
        description,
        type: typeValue.value,
        end_date: endDate,
        for_widget: forWidget,
        insurance_code: currentCompany.value,
        risk: currentRisk.value,
        bank_code: currentBank.value,
        limit_kv: limitKv,
        limit_insurance: limitInsurance,
        agents: agentsId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setStocksOption([]);
          Array.isArray(response) && response.map((item) => {
            changeOptions(item);
          });
          showModalInfo('Новая акция создана');
          changeData(0);
        } else {
          showModalInfo('Не удалось создать новую акцию');
        }
      })
      .catch(() => {
        showModalInfo('Не удалось создать новую акцию');
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const stockDelete = (id) => {
    setIndexLoad(id);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/deleteStock`, {
      method: 'delete',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setStocksOption([]);
          Array.isArray(response) && response.map((item) => {
            changeOptions(item);
          });
          showModalInfo('Акция удалена');
        } else {
          showModalInfo('Не удалось удалить акцию');
        }
        setIndexLoad(0);
      })
      .catch(() => {
        showModalInfo('Не удалось удалить акцию');
        setIndexLoad(0);
      });
  };

  useEffect(() => {
    getCompanies(lsToken, 'mortgage').then((response) => {
      const companiesOptions = Object.entries(response).map((company) => ({ value: company[0], label: company[1].name }));
      setCompanies(companiesOptions);
    });
    getBanks(lsToken).then((response) => response.forEach((bank) => {
      setBanksOption((banks) => [...banks, { label: bank.name, value: bank.code }]);
    }));
  }, []);

  useEffect(() => {
    if (!stocksOption.length) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/stocks`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            Array.isArray(response) && response.map((item) => {
              changeOptions(item);
            });
          } else {
            showModalInfo('Не удалось получить акции');
          }
        })
        .catch(() => {
          showModalInfo('Не удалось получить акции');
        });
    }
  }, []);

  return (
    <div className="card mb-4">
      <div className="card-body">
        {activeId ? <button style={{ marginTop: 0 }} onClick={() => changeData(0)} type="button" className="btn btn-success">Назад к созданию</button> : null}
        <div className="form-group row">
          <div className="col-lg-3 col-xl-3">
            <label htmlFor="titleTag">Страховая компания</label>
            <Select
              classNamePrefix="react-select"
              styles={customStyles}
              name="flatMaterial"
              noOptionsMessage={() => 'Не найдено'}
              inputId="flatMaterial"
              value={currentCompany}
              onChange={(e) => setCurrentCompany(e)}
              options={companies}
              placeholder="Страховая"
            />
          </div>
          <div className="col-lg-3 col-xl-3">
            <label htmlFor="titleTag">Описание акции</label>
            <input
              className="form-control"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              name="titleTag"
              id="titleTag"
              placeholder="Описание"
            />
          </div>
          <div className="col-lg-3 col-xl-3">
            <label htmlFor="titleTag">Скидка</label>
            <input
              className="form-control"
              onChange={(e) => setNumber(e.target.value)}
              value={number}
              name="titleTag"
              id="titleTag"
              placeholder="0"
              type="number"
            />
          </div>
          <div className="col-lg-3 col-xl-3">
            <label htmlFor="bank">Тип значения</label>
            <Select
              classNamePrefix="react-select"
              styles={customStyles}
              name="flatMaterial"
              noOptionsMessage={() => 'Не найдено'}
              inputId="flatMaterial"
              value={typeValue}
              onChange={(e) => setTypeValue(e)}
              options={type}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3 col-sm-12">
            <label htmlFor="agentId">Агент</label>
            <AsyncSelect
              styles={customSelectStyle()}
              name="agentId"
              placeholder="Укажите агента"
              classNamePrefix="react-select"
              inputId="agentId"
              value={agentsId}
              isMulti
              onChange={(e) => setAgentsId(e)}
              className="form-control-custom"
              loadOptions={debounce(loadSuggestions(''), 2000)}
              noOptionsMessage={() => 'Не найдено'}
            />
          </div>
          <div className="col-lg-3 col-xl-3">
            <label htmlFor="policyDate">Дата окончания действия</label>
            <ReactDatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              className={classnames('form-control')}
              selected={endDate && parseISO(endDate)}
              onChange={(date) => setEndDate(format(date, "yyyy-MM-dd'T'HH:mm:ss"))}
              id="policyDate"
              locale={ru}
              minDate={addDays(new Date(), 1)}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <ReactInputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label htmlFor="bank">Банк</label>
            <Select
              classNamePrefix="react-select"
              styles={customStyles}
              name="flatMaterial"
              noOptionsMessage={() => 'Не найдено'}
              inputId="flatMaterial"
              value={currentBank}
              onChange={(e) => setCurrentBank(e)}
              options={banksOption}
              placeholder="Банк"
            />
          </div>
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label htmlFor="bank">Лимит скидки страховой %</label>
            <input
              className="form-control"
              onChange={(e) => setLimitInsurance(e.target.value)}
              value={limitInsurance}
              name="titleTag"
              id="titleTag"
              placeholder="0"
              type="number"
              max="100"
            />
          </div>
          {/*<div className="col-lg-3  mb-3 mb-lg-0">
            <label htmlFor="bank">Лимит скидки КВ %</label>
            <input
              className="form-control"
              onChange={(e) => setLimitKv(e.target.value)}
              value={limitKv}
              name="titleTag"
              id="titleTag"
              placeholder="0"
              type="number"
              max="100"
            />
          </div>*/}
          <div className="col-lg-3 col-xl-3">
            <label htmlFor="bank">Риск</label>
            <Select
              classNamePrefix="react-select"
              styles={customStyles}
              name="flatMaterial"
              noOptionsMessage={() => 'Не найдено'}
              inputId="flatMaterial"
              value={currentRisk}
              onChange={(e) => setCurrentRisk(e)}
              options={products}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3 mb-3 mb-lg-0">
            <div className="form-check custom-checkbox">
              <input
                onChange={(e) => setForWidget(e.target.checked)}
                checked={forWidget}
                name="forWidget"
                className="form-check-input checkbox-styled"
                type="checkbox"
                id="forWidget"
              />
              <label className="form-check-label" htmlFor="forWidget">Для виджета</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3 mb-3 mb-lg-0">
            <button type="button" className="btn btn-success" onClick={activeId ? updateStock : stockCreate}>Сохранить</button>
            {load ? <ReactLoading type="spin" height={38} width={38} className="loading-circle ml-3 d-inline-block" /> : null}
          </div>
        </div>
        {stocksOption.length ? stocksOption.map((item) => (
          <div className="form-group row">
            <div className="col-lg-4 mb-3 mb-lg-0">{item.label}</div>
            <button style={{ marginTop: 0 }} onClick={() => changeData(item.value)} type="button" className="btn btn-success mr-3">Изменить</button>
            <button disabled={item.value === indexLoad} style={{ marginTop: 0 }} onClick={() => stockDelete(item.value)} type="button" className="btn btn-border btn-danger">Удалить</button>
            {item.value === indexLoad ? <ReactLoading type="spin" height={38} width={38} className="loading-circle ml-3" /> : null}
          </div>
        )) : null}
      </div>
    </div>
  );
}

export default forwardRef(Stocks);
