import React from 'react';
import {
  AcademicCapIcon,
  AdjustmentsHorizontalIcon,
  AdjustmentsVerticalIcon,
  ArchiveBoxArrowDownIcon,
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  ArrowDownCircleIcon,
  ArrowDownLeftIcon,
  ArrowDownOnSquareIcon,
  ArrowDownOnSquareStackIcon,
  ArrowDownRightIcon,
} from '@heroicons/react/24/solid';

export default function DynamicIcon(props) {
  const { nameIcon } = props;

  let icon = '';

  switch (nameIcon) {
    case 'AcademicCapIcon':
      icon = (
        <AcademicCapIcon />
      );
      break;
    case 'AdjustmentsHorizontalIcon':
      icon = (
        <AdjustmentsHorizontalIcon />
      );
      break;
    case 'AdjustmentsVerticalIcon':
      icon = (
        <AdjustmentsVerticalIcon />
      );
      break;
    case 'ArchiveBoxArrowDownIcon':
      icon = (
        <ArchiveBoxArrowDownIcon />
      );
      break;
    case 'ArchiveBoxXMarkIcon':
      icon = (
        <ArchiveBoxXMarkIcon />
      );
      break;
    case 'ArchiveBoxIcon':
      icon = (
        <ArchiveBoxIcon />
      );
      break;
    case 'ArrowDownCircleIcon':
      icon = (
        <ArrowDownCircleIcon />
      );
      break;
    case 'ArrowDownRightIcon':
      icon = (
        <ArrowDownRightIcon />
      );
      break;
    case 'ArrowDownLeftIcon':
      icon = (
        <ArrowDownLeftIcon />
      );
      break;
    case 'AcademicCapIcodn':
      icon = (
        <AcademicCapIcon />
      );
      break;
    case 'ArrowDownOnSquareStackIcon':
      icon = (
        <ArrowDownOnSquareStackIcon />
      );
      break;
    case 'ArrowDownOnSquareIcon':
      icon = (
        <ArrowDownOnSquareIcon />
      );
      break;
    default:
      break;
  }

  return (
    <div className={props.className}>
      {
        nameIcon && icon ? (
          <div>
            {icon}
          </div>
        ) : null
      }
    </div>
  );
}
