import React, {
  forwardRef, useState, useEffect,
  useMemo, useContext,
} from 'react';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { customFetch, customSelectStyle } from '../../Utils/Helpers';
import {
  ModalMessagesContext,
} from '../../Utils/ContextsServices/ModalMessagesService';
import DynamicIcon from '../../Utils/UiComponents/DynamicIcon';

function TagsProduct({ setTags, tags }) {
  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const tagsOption = [
    { label: 'AcademicCapIcon', value: 'AcademicCapIcon' },
    { label: 'AdjustmentsHorizontalIcon', value: 'AdjustmentsHorizontalIcon' },
    { label: 'AdjustmentsVerticalIcon', value: 'AdjustmentsVerticalIcon' },
    { label: 'ArchiveBoxArrowDownIcon', value: 'ArchiveBoxArrowDownIcon' },
    { label: 'ArchiveBoxIcon', value: 'ArchiveBoxIcon' },
    { label: 'ArchiveBoxXMarkIcon', value: 'ArchiveBoxXMarkIcon' },
    { label: 'ArrowDownCircleIcon', value: 'ArrowDownCircleIcon' },
    { label: 'ArrowDownLeftIcon', value: 'ArrowDownLeftIcon' },
    { label: 'ArrowDownOnSquareIcon', value: 'ArrowDownOnSquareIcon' },
    { label: 'ArrowDownOnSquareStackIcon', value: 'ArrowDownOnSquareStackIcon' },
    { label: 'ArrowDownRightIcon', value: 'ArrowDownRightIcon' },
  ];

  const [currentTag, setCurrentTag] = useState(null);
  const [visibleTagPopup, setVisibleTagPopup] = useState(false);
  const [title, setTitle] = useState('');
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [tooltipData, setTooltipData] = useState('');

  const customStyles = useMemo(() => customSelectStyle(), []);

  const tagCreate = () => {
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/createTag`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        formData: {
          title,
          outline: 'outline',
          visible_popup: visibleTagPopup,
          icon_name: currentTag.value,
          popup: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
          tooltip: tooltipData,
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setTags(response);
          showModalInfo('Новый тег успешно создан');
          setTitle('');
          setTooltipData('');
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        showModalInfo(error);
      });
  };

  const tagDelete = (id) => {
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/deleteTag`, {
      method: 'delete',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setTags(response);
          showModalInfo('Тег успешно удален');
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        showModalInfo(error);
      });
  };

  return (
    <>
      <div>
        <div className="form-group row">
          <div className="col-lg-4 col-xl-3">
            <label className="form-check-label">Выбор тега</label>
            <Select
              classNamePrefix="react-select"
              styles={customStyles}
              name="flatMaterial"
              noOptionsMessage={() => 'Не найдено'}
              inputId="flatMaterial"
              value={currentTag}
              placeholder="Тег"
              onChange={(e) => setCurrentTag(e)}
              options={tagsOption}
            />
          </div>
          {/* <div className="col-lg-4 col-xl-2 ml-4">
            <input
              onChange={() => setOutline(!outline)}
              checked={outline}
              className="form-check-input checkbox-styled"
              type="checkbox"
              id="driverForeignLicence"
            />
            <label className="form-check-label">Контур</label>
          </div> */}
          <div className="col-lg-4 col-xl-2 ml-4">
            <input
              onChange={() => setVisibleTagPopup(!visibleTagPopup)}
              checked={visibleTagPopup}
              className="form-check-input checkbox-styled"
              type="checkbox"
              id="driverForeignLicence"
            />
            <label className="form-check-label">Отображение тега в Popup</label>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-xl-4">
            <div className="dynamic-tag">
              <DynamicIcon className="dynamic-tag__icon" nameIcon={currentTag && currentTag.value} />
              <span className="dynamic-tag__title">{title.charAt(0).toUpperCase() + title.slice(1)}</span>
              {tooltipData.length ? <QuestionMarkCircleIcon className="dynamic-tag__tooltip" /> : null}
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-4 col-xl-3">
            <label htmlFor="passport">Tooltip</label>
            <InputMask
              onChange={(e) => setTooltipData(e.target.value)}
              id="passport"
              name="passport"
              className="form-control"
              value={tooltipData}
            />
          </div>
          <div className="col-lg-4 col-xl-3">
            <label htmlFor="titleTag">Название тега</label>
            <input
              className="form-control"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              name="titleTag"
              id="titleTag"
            />
          </div>
        </div>
        <div>
          <label htmlFor="passport">Popup</label>
          <Editor
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history'],
              blockType: {
                inDropdown: true,
                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
              },
            }}
            stripPastedStyles
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="form-control"
            onEditorStateChange={setEditorState}
          />
        </div>
        <button type="button" className="btn btn-success mr-3 mb-3 mt-3" onClick={tagCreate}>Сохранить</button>
      </div>
      {
        tags.map((tag) => (
          <div className="form-group row">
            <div className="col-xl-4">
              <div className="dynamic-tag" style={{ padding: 0 }}>
                <DynamicIcon className="dynamic-tag__icon" type={tag.outline ? 'outline' : 'solid'} nameIcon={tag.icon_name} />
                <span className="dynamic-tag__title">{tag.title.charAt(0).toUpperCase() + tag.title.slice(1)}</span>
                {tag.tooltip ? <QuestionMarkCircleIcon className="dynamic-tag__tooltip" /> : null}
              </div>
            </div>
            <button style={{ marginTop: 0 }} onClick={() => tagDelete(tag.id)} type="button" className="btn btn-border btn-danger">Удалить тег</button>
          </div>
        ))
      }
    </>
  );
}

export default forwardRef(TagsProduct);
