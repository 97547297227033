import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { ChromePicker } from 'react-color';
import Select from 'react-select';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faLongArrowAltDown,
  faLongArrowAltUp,
} from '@fortawesome/fontawesome-free-solid';
import InputMask from 'react-input-mask';
import CreatableSelect from 'react-select/creatable';
import {
  TabContent, TabPane,
} from 'reactstrap';
import Modal from 'react-responsive-modal';
import queryString from 'query-string';
import { format, parseISO } from 'date-fns';
import OutsideClickHandler from 'react-outside-click-handler';
import WidgetAnalytics from './WidgetAnalytics';
import WidgetPolicesList from './WidgetPolicesList';
import WidgetCRMList from './WidgetCRMList';
import WidgetAnalyticsIntegrationList from './WidgetAnalyticsIntegrationList';
import WidgetInsurancesSettings from './WidgetInsurancesSettings';
import WidgetCallbackSettings from './WidgetCallbackSettings';
import { customFetch, customSelectStyle } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import LoadingBanner from '../Utils/UiComponents/LoadingBanner';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';
import FormNotificationColorLine from '../Layout/FormNotifications/FormNotificationColorLine';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';
import WidgetStocks from './WidgetStocks';

class Widget extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      loading: false,
      dropDown: '',
      defaultPaymentRedirectLink: `https://${window.location.hostname.split('.')[0]}.polis.online/thank_you`,
      showUrls: false,
      showWidgetComponentLoad: true,
      showWidgetComponent: false,
      openModalSure: false,
      goToWidgetsList: false,
      displayColorPickerIcon: false,
      displayColorPickerButton: false,
      displayColorPickerBuyButton: false,
      displayColorPickerBuyButtonFont: false,
      displayColorPickerButtonHover: false,
      displayColorPickerBackground: false,
      displayColorPickerMainFont: false,
      displayColorPickerAgainstMainColorFont: false,
      displayColorPickerButtonFont: false,
      displayColorPickerButtonGradientColorStart: false,
      displayColorPickerButtonGradientColorEnd: false,
      displayColorPickerCheckboxDot: false,
      displayColorPickerAllocationBest: false,
      displayColorPickerAllocationSafe: false,
      displayColorPickerAllocationRecommend: false,
      displayColorPickerAllocationPrice: false,
      fontList: [],
      fontSelectList: [],
      selectedFont: { value: '1', label: 'Roboto' },
      activeTab: '1',
      widget: {
        apiKey: '',
        name: '',
        id: 0,
        stocks: [],
        type_insurance: 1,
        settings: {
          osago_landing_page: '',
          full_width: true,
          width: '',
          widthType: { value: '1', label: 'px' },
          main_color: '#fff',
          button_color: '#fff',
          button_hover_color: '#fff',
          button_gradient_color_start: '#fff',
          button_gradient_color_end: '#fff',
          buy_button_color: '#fff',
          buy_button_font_color: '#fff',
          main_font_color: '#000',
          against_main_color_font_color: '#000',
          button_font_color: '#fff',
          show_begin_steps: true,
          show_badges: true,
          button_border_radius: false,
          payment_new_tab: true,
          background_transparent: false,
          button_gradient_active: false,
          background_color: 'transparent',
          yandexId: '',
          googleId: '',
          calltouch_id: '',
          yandex_login: '',
          yandex_oauth_token: '',
          bitrix24IntegrationIsActive: true,
          amoIntegrationIsActive: true,
          send_lead_to_infull: false,
          hide_zero_commission_insurances: false,
          limit_insurances_by_commission: false,
          commission_limit: 0,
          payment_redirect_url: '',
          send_callback_sms: true,
          send_callback_email: true,
          neutral_sms_sender: false,
          allocation_best: '#fff',
          allocation_safe: '#fff',
          allocation_recommend: '#fff',
          allocation_price: '#fff',
          img: '',
          tag_icon: false,
          header_visible: false,
        },
        policy_type: {
          code: '',
        },
        font: {},
        help_texts: {
          base: '',
          header: '',
          steps: {
            one: '',
            two: '',
            three: '',
            four: '',
            five: '',
          },
        },
      },
      policeType: { value: '1', label: 'ОСАГО' },
      lidogenerateType: { value: 'before', label: 'До вывода результатов' },
      emailListInputValue: '',
      emailListValue: [],
      emailListError: '',
      widgetNotFound: false,
      widgetLoadingError: false,
      policeTypesList: [],
    };
  }

  componentDidMount() {
    document.title = 'Редактирование виджета';
    this.getFontList();
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
    this.getWidgetTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      showWidgetComponent,
      activeTab,
      widget,
    } = this.state;
    if ((!prevState.showWidgetComponent && showWidgetComponent) || ((activeTab === '1' || activeTab === '2') && prevState.activeTab !== '1' && prevState.activeTab !== '2')) {
      const oldScriptOsago = document.getElementById('osago-widget-script');
      const oldScriptGreenCard = document.getElementById('green-card-widget-script');
      const oldScriptDkp = document.getElementById('dkp-widget-script');
      const oldScriptKbm = document.getElementById('kbm-widget-script');
      const oldScriptProperty = document.getElementById('property-widget-script');
      const oldScriptDms = document.getElementById('dms-widget-script');
      const oldScriptMortgage = document.getElementById('mortgage-widget-script');
      const oldScriptMortgageLife = document.getElementById('mortgageLife-widget-script');
      if (oldScriptOsago) {
        oldScriptOsago.remove();
      }
      if (oldScriptGreenCard) {
        oldScriptGreenCard.remove();
      }
      if (oldScriptDkp) {
        oldScriptDkp.remove();
      }
      if (oldScriptKbm) {
        oldScriptKbm.remove();
      }
      if (oldScriptDms) {
        oldScriptDms.remove();
      }
      if (oldScriptProperty) {
        oldScriptProperty.remove();
      }
      if (oldScriptMortgage) {
        oldScriptMortgage.remove();
      }
      if (oldScriptMortgageLife) {
        oldScriptMortgageLife.remove();
      }
      const script = document.createElement('script');

      if (widget.policy_type.code === 'osago') {
        script.src = `${process.env.REACT_APP_FRONT_DOMAIN}/widget_v2.min.js`;
        script.id = 'osago-widget-script';
      } else if (widget.policy_type.code === 'greenCard') {
        script.src = `${process.env.REACT_APP_FRONT_DOMAIN}/widget_gk_v2.min.js`;
        script.id = 'green-card-widget-script';
      } else if (widget.policy_type.code === 'dkp') {
        script.src = `${process.env.REACT_APP_FRONT_DOMAIN}/widget_dkp_v2.min.js`;
        script.id = 'dkp-widget-script';
      } else if (widget.policy_type.code === 'kbm') {
        script.src = `${process.env.REACT_APP_FRONT_DOMAIN}/widget_kbm_v2.min.js`;
        script.id = 'kbm-widget-script';
      } else if (widget.policy_type.code === 'property') {
        script.src = `${process.env.REACT_APP_FRONT_DOMAIN}/widget_property_v2.min.js`;
        script.id = 'property-widget-script';
      } else if (widget.policy_type.code === 'dmsStudent') {
        script.src = `${process.env.REACT_APP_FRONT_DOMAIN}/widget_dms_v2.min.js`;
        script.id = 'dms-widget-script';
      } else if (widget.policy_type.code === 'mortgage') {
        script.src = `${process.env.REACT_APP_FRONT_DOMAIN}/widget_mortgage_v2.min.js`;
        script.id = 'mortgage-widget-script';
      } else if (widget.policy_type.code === 'mortgageLife') {
        script.src = `${process.env.REACT_APP_FRONT_DOMAIN}/widget_mortgage_life_v2.min.js`;
        script.id = 'mortgageLife-widget-script';
      }

      script.async = true;
      document.body.appendChild(script);
    }
  }

  getWidgetTypes = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets/get-types`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((policeTypesList) => {
        policeTypesList = policeTypesList.map((type) => ({ label: type.name, value: type.id }));
        this.setState({ policeTypesList });
      });
  }

  getFontList = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widget-fonts`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const fontSelectList = [];
        data.forEach((font, index) => {
          fontSelectList.push({ value: (index).toString(), label: font.name });
        });
        this.setState({
          fontSelectList,
          fontList: data,
        }, () => this.loadWidget());
      });
  };

  toggleDeleteModalWindow = () => {
    this.setState((prevState) => ({
      openModalSure: !prevState.openModalSure,
    }));
  };

  handleSubmitDeleteSure = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { id } = this.props.match.params;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widget/${id}`, {
      method: 'delete',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then(() => {
        const { history } = this.props;
        history.push('/widgets');
      });
  };

  loadWidget = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { id } = this.props.match.params;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widget-with-api-key/${id}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'Resource not found') {
          this.setState({ widgetNotFound: true });
        } else if (data.error) {
          this.setState({ widgetLoadingError: data.error });
        } else {
          switch (data.widget.settings.width_type_id) {
            case 1:
              data.widget.settings.widthType = { value: '1', label: 'px' };
              break;
            case 2:
              data.widget.settings.widthType = { value: '2', label: '%' };
              break;
            default:
              data.widget.settings.widthType = { value: '2', label: '%' };
              break;
          }

          let { lidogenerateType, emailListValue } = this.state;

          switch (data.widget.lidogenerate_after_calculate) {
            case 'before':
              lidogenerateType = { value: 'before', label: 'До вывода результатов' };
              break;
            case 'after':
              lidogenerateType = { value: 'after', label: 'После выдачи результатов' };
              break;
            case 'none':
              lidogenerateType = { value: 'none', label: 'Отключить проверку телефона' };
              break;
            default:
              lidogenerateType = { value: 'before', label: 'До вывода результатов' };
              break;
          }

          const { fontSelectList, policeTypesList } = this.state;
          const selectedFont = fontSelectList.filter((selectFont) => selectFont.label === data.widget.font.name)[0];
          data.widget.apiKey = data.apiKey[0].key;
          emailListValue = data.widget.settings.email_list.split(',').map((value) => ({ label: value, value }));
          const policeType = policeTypesList.filter((type) => type.value === data.widget.type_insurance);
          this.setState({
            widget: data.widget,
            selectedFont,
            lidogenerateType,
            emailListValue,
            policeType,
          }, () => {
            setTimeout(() => {
              this.setState({ showWidgetComponent: true }, () => {
                this.setState({ showWidgetComponentLoad: false });
              });
            }, 600);
          });
        }
      })
      .catch(() => {
        this.setState({ widgetLoadingError: 'Недостаточно прав' });
      });
  };

  updateWidget = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { id } = this.props.match.params;
    const { widget, activeTab } = this.state;
    const integrationType = activeTab === '9' ? 'admin' : 'agent';
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widget/${id}?integrationType=${integrationType}`, {
      method: 'put',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ widget }),
    })
      .then((response) => response.json())
      .then(() => {
        this.setState((prevState) => ({ showWidgetComponent: !prevState.showWidgetComponent, showWidgetComponentLoad: !prevState.showWidgetComponentLoad }), () => {
          this.setState((prevState) => ({ showWidgetComponent: !prevState.showWidgetComponent }), () => {
            setTimeout(() => { this.setState((prevState) => ({ showWidgetComponentLoad: !prevState.showWidgetComponentLoad })); }, 150);
          });
        });
      });
  };

  updateWidgetWithInfo = () => {
    const { showModalInfo } = this.context;
    this.updateWidget();
    showModalInfo('Изменения сохранены');
  };

  changeWidgetName = (event) => {
    const { target } = event;
    this.setState((prevState) => ({
      widget: {
        ...prevState.widget,
        name: target.value,
      },
    }));
  };

  changeWidgetOsagoLandingPage = (event) => {
    const { target } = event;
    this.setState((prevState) => ({
      widget: {
        ...prevState.widget,
        settings: {
          ...prevState.widget.settings,
          osago_landing_page: target.value,
        },
      },
    }));
  }

  changeWidgetWidth = (event) => {
    const { widget } = this.state;
    const { target } = event;

    event.preventDefault();
    if (!widget.settings.full_width) {
      let width;
      if (widget.settings.widthType.label === '%' && parseInt(target.value, 10) > 100) {
        width = '100';
      } else {
        width = target.value;
      }
      this.setState((prevState) => ({
        widget: {
          ...prevState.widget,
          settings: {
            ...prevState.widget.settings,
            width,
          },
        },
      }), () => this.updateWidget());
    }
  };

  sendImage = () => {
    const { widget } = this.state;
    const formData = new FormData();
    formData.append('image', widget.settings.img);
    formData.append('widget', widget.id);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widget/uploadImage`, {
      method: 'POST',
      headers: {
        Authorization: lsToken,
      },
      body: formData,
    })
      .then((response) => response.json());
  }

  uploadImage = (event) => {
    this.setState((prevState) => ({
      widget: {
        ...prevState.widget,
        settings: {
          ...prevState.widget.settings,
          img: event[0],
        },
      },
    }), () => this.sendImage());
  }

  deleteImage = () => {
    const { widget } = this.state;
    const formData = new FormData();
    formData.append('widget', widget.id);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widget/deleteImage`, {
      method: 'POST',
      headers: {
        Authorization: lsToken,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'ok') {
          this.setState((prevState) => ({
            widget: {
              ...prevState.widget,
              settings: {
                ...prevState.widget.settings,
                img: '',
              },
            },
          }));
        }
      });
  }

  validWidgetWidth = () => {
    const { showModalInfo } = this.context;
    const { widget } = this.state;
    if (!widget.settings.full_width) {
      showModalInfo('Изменения сохранены');
    }
  };

  handleChangeWidgetWidthType = (e) => {
    const { widget } = this.state;
    let { width } = widget.settings;
    if (e.label === '%' && parseInt(width, 10) > 100) {
      width = '100';
    }
    this.setState((prevState) => ({
      widget: {
        ...prevState.widget,
        settings: {
          ...prevState.widget.settings,
          widthType: e,
          width,
        },
      },
    }), () => this.updateWidget());
    this.validWidgetWidth();
  };

  handleChangeWidgetType = (e) => {
    this.setState((prevState) => ({
      policeType: e,
      widget: {
        ...prevState.widget,
        type_insurance: e.value,
      },
    }), () => this.updateWidgetWithInfo());
  }

  openColorPicker = (type) => {
    const typeName = `displayColorPicker${type}`;
    this.setState({ [typeName]: true });
  };

  closeColorPicker = () => {
    this.setState({
      displayColorPickerIcon: false,
      displayColorPickerButtonHover: false,
      displayColorPickerButton: false,
      displayColorPickerBuyButtonFont: false,
      displayColorPickerBuyButton: false,
      displayColorPickerBackground: false,
      displayColorPickerMainFont: false,
      displayColorPickerAgainstMainColorFont: false,
      displayColorPickerButtonFont: false,
      displayColorPickerCheckboxDot: false,
      displayColorPickerButtonGradientColorStart: false,
      displayColorPickerButtonGradientColorEnd: false,
      displayColorPickerAllocationBest: false,
      displayColorPickerAllocationSafe: false,
      displayColorPickerAllocationRecommend: false,
      displayColorPickerAllocationPrice: false,
    }, () => {
      this.updateWidgetWithInfo();
    });
  };

  changeWidgetColor = (color, field) => {
    this.setState((prevState) => ({
      widget: {
        ...prevState.widget,
        settings: {
          ...prevState.widget.settings,
          [field]: color.hex,
        },
      },
    }));
  };

  copyWidgetLink = () => {
    const { showModalInfo } = this.context;
    const widgetLinkText = document.getElementById('widgetLinkText');
    const range = document.createRange();

    window.getSelection().removeAllRanges();
    range.selectNode(widgetLinkText);
    window.getSelection().addRange(range);
    try {
      document.execCommand('copy');
      showModalInfo('Текст скопирован в буфер обмена');
    } catch (err) {
      console.log('Can`t copy');
    }

    window.getSelection().removeAllRanges();
  };

  handleChangeWidgetFont = (e) => {
    const index = parseInt(e.value, 10);
    this.setState((prevState) => ({
      selectedFont: e,
      widget: {
        ...prevState.widget,
        font: prevState.fontList[index],
      },
    }), () => {
      this.updateWidgetWithInfo();
    });
  };

  handleChangeWidgetLidogenerateType = (e) => {
    this.setState((prevState) => ({
      lidogenerateType: e,
      widget: {
        ...prevState.widget,
        lidogenerate_after_calculate: e.value,
      },
    }), () => {
      this.updateWidgetWithInfo();
    });
  };

  emailListHandleChange = (emailListValue) => {
    if (!emailListValue || emailListValue.length === 0) {
      this.setState(
        { emailListError: 'Должен быть указан хотя бы один email адрес' },
        () => setTimeout(() => { this.setState({ emailListError: '' }); }, 5000));
    } else {
      const email_list = emailListValue.map((email) => email.value).join(',');
      this.setState((prevState) => ({
        emailListValue,
        widget: {
          ...prevState.widget,
          settings: {
            ...prevState.widget.settings,
            email_list,
          },
        },
      }), () => {
        this.updateWidgetWithInfo();
      });
    }
  };

  emailListHandleInputChange = (emailListInputValue) => {
    this.setState({ emailListInputValue });
  };

  emailListHandleKeyDown = (event) => {
    const createOption = (label) => ({
      label,
      value: label,
    });
    const { emailListInputValue } = this.state;
    let { emailListValue } = this.state;
    if (!emailListInputValue) return;

    if (event.type === 'blur') {
      if (emailListValue.length > 2) {
        this.setState({ emailListError: 'Максимальное кол-во адресов - 3' },
          () => setTimeout(() => { this.setState({ emailListError: '' }); }, 5000));
        return;
      }
      if (!/^[^\s]+@[^\s]+\.[^\s]+$/.test(emailListInputValue)) {
        this.setState({ emailListError: 'Указан некорректный email адрес' },
          () => setTimeout(() => { this.setState({ emailListError: '' }); }, 5000));
        return;
      }
      emailListValue = [...emailListValue, createOption(emailListInputValue)];
      const email_list = emailListValue.map((email) => email.value).join(',');
      this.setState((prevState) => ({
        emailListInputValue: '',
        emailListValue,
        widget: {
          ...prevState.widget,
          settings: {
            ...prevState.widget.settings,
            email_list,
          },
        },
      }), () => {
        this.updateWidgetWithInfo();
      });
    } else if (event.type === 'keydown') {
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          if (emailListValue.length > 2) {
            this.setState({ emailListError: 'Максимальное кол-во адресов - 3' },
              () => setTimeout(() => { this.setState({ emailListError: '' }); }, 5000));
            return;
          }
          if (!/^[^\s]+@[^\s]+\.[^\s]+$/.test(emailListInputValue)) {
            this.setState({ emailListError: 'Указан некорректный email адрес' },
              () => setTimeout(() => { this.setState({ emailListError: '' }); }, 5000));
            return;
          }
          emailListValue = [...emailListValue, createOption(emailListInputValue)];
          const email_list = emailListValue.map((email) => email.value).join(',');
          this.setState((prevState) => ({
            emailListInputValue: '',
            emailListValue,
            widget: {
              ...prevState.widget,
              settings: {
                ...prevState.widget.settings,
                email_list,
              },
            },
          }), () => {
            this.updateWidgetWithInfo();
          });
          event.preventDefault();
      }
    }
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    window.history.replaceState(null, null, `?activeTab=${tab}`);
  };

  handleChangeHelpText = (e, step) => {
    const { value } = e.target;
    if (step === 'base' || step === 'baseHeader' || step === 'header') {
      this.setState((prevState) => ({
        widget: {
          ...prevState.widget,
          help_texts: {
            ...prevState.widget.help_texts,
            [step]: value,
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        widget: {
          ...prevState.widget,
          help_texts: {
            ...prevState.widget.help_texts,
            steps: {
              ...prevState.widget.help_texts.steps,
              [step]: value,
            },
          },
        },
      }));
    }
  };

  downloadReport = (e) => {
    e.preventDefault();
    this.child.current.downloadReport();
  };

  customizeTable = (e) => {
    e.preventDefault();
    this.child.current.customizeTable();
  };

  changeCRMIntegrationActive = (e, type, integrationType) => {
    if (integrationType === 'agent') {
      this.setState((prevState) => ({
        ...prevState,
        widget: {
          ...prevState.widget,
          settings: {
            ...prevState.widget.settings,
            bitrix24IntegrationIsActive: type === 'bitrix24' ? !prevState.widget.settings.bitrix24IntegrationIsActive : prevState.widget.settings.bitrix24IntegrationIsActive,
            amoIntegrationIsActive: type === 'amo' ? !prevState.widget.settings.amoIntegrationIsActive : prevState.widget.settings.amoIntegrationIsActive,
          },
        },
      }), () => {
        this.updateWidgetWithInfo();
      });
    }
    if (integrationType === 'admin') {
      this.setState((prevState) => ({
        ...prevState,
        widget: {
          ...prevState.widget,
          settings: {
            ...prevState.widget.settings,
            send_lead_to_infull: type === 'bitrix24' ? !prevState.widget.settings.send_lead_to_infull : prevState.widget.settings.send_lead_to_infull,
          },
        },
      }), () => {
        this.updateWidgetWithInfo();
      });
    }
  };

  changeWidgetAnalyticsIntegrationId = (e, field) => {
    const { target } = e;
    this.setState((prevState) => ({
      ...prevState,
      widget: {
        ...prevState.widget,
        settings: {
          ...prevState.widget.settings,
          [field]: target.value,
        },
      },
    }));
  };

  changeCommissionLimit = (e, callback) => {
    let inputValue;
    if (e.target === undefined) {
      inputValue = e;
    } else {
      const { target } = e;
      inputValue = target.value;
    }
    this.setState((prevState) => ({
      ...prevState,
      widget: {
        ...prevState.widget,
        settings: {
          ...prevState.widget.settings,
          commission_limit: inputValue,
        },
      },
    }), () => {
      if (callback !== undefined) {
        callback();
      }
    });
  };

  changePaymentRedirectLink = (e) => {
    const { target } = e;
    this.setState((prevState) => ({
      widget: {
        ...prevState.widget,
        settings: {
          ...prevState.widget.settings,
          payment_redirect_url: target.value,
        },
      },
    }));
  }

  toggleFlag = (e) => {
    const { target } = e;
    const { widget } = this.state;
    const { settings } = widget;

    let backgroundColor = settings.background_transparent;
    if (target.id === 'background_transparent') {
      backgroundColor = 'transparent';
    }

    let widgetWidthNumber = '';
    let { widthType } = settings;
    if (target.id === 'full_width' && widget.settings.full_width) {
      widgetWidthNumber = '100';
      widthType = { value: '2', label: '%' };
    }

    this.setState((prevState) => ({
      widget: {
        ...prevState.widget,
        settings: {
          ...prevState.widget.settings,
          [target.id]: !prevState.widget.settings[target.id],
          background_color: backgroundColor,
          width: widgetWidthNumber,
          widthType,
        },
      },
    }), () => {
      this.updateWidgetWithInfo();
    });
  }

  goToAuthor = (e, agentId) => {
    const { history } = this.props;
    e.preventDefault();
    history.push(`/agent/${agentId}`);
  };

  showUrls = () => {
    this.setState((prevState) => ({ showUrls: !prevState.showUrls }));
  };

  dropDownBlockClose = () => {
    const { dropDown } = this.state;
    if (dropDown !== '') {
      this.setState({ dropDown: '' });
    }
  };

  dropDownBlock = () => {
    this.setState((prevState) => ({
      dropDown: prevState.dropDown === 'opened' ? '' : 'opened',
    }));
  };

  copyWidget = (e) => {
    e.preventDefault();
    const { widget } = this.state;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ loading: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widget/${widget.id}/copy`, {
      method: 'POST',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const { history } = this.props;
        history.push(`/widgets/${response.id}`);
        this.setState({ loading: false, dropDown: '' });
      });
  }

  render() {
    const {
      loading,
      openModalSure,
      widget,
      policeType,
      displayColorPickerIcon,
      lidogenerateType,
      displayColorPickerButton,
      displayColorPickerBuyButtonFont,
      displayColorPickerBuyButton,
      displayColorPickerButtonHover,
      displayColorPickerBackground,
      displayColorPickerMainFont,
      displayColorPickerAgainstMainColorFont,
      displayColorPickerButtonFont,
      displayColorPickerButtonGradientColorStart,
      displayColorPickerButtonGradientColorEnd,
      displayColorPickerCheckboxDot,
      displayColorPickerAllocationBest,
      displayColorPickerAllocationPrice,
      displayColorPickerAllocationRecommend,
      displayColorPickerAllocationSafe,
      goToWidgetsList,
      fontSelectList,
      selectedFont,
      emailListInputValue,
      emailListValue,
      emailListError,
      activeTab,
      widgetNotFound,
      widgetLoadingError,
      showWidgetComponent,
      showWidgetComponentLoad,
      policeTypesList,
      defaultPaymentRedirectLink,
      showUrls,
      dropDown,
    } = this.state;
    const {
      history, setTour, setDataLoadFunctions, userInfo,
    } = this.props;

    const components = {
      DropdownIndicator: null,
    };

    const customStyles = customSelectStyle();

    const codeStyle = {
      backgroundColor: '#777777',
      color: 'white',
      borderRadius: '4px',
      fontSize: '16px',
      padding: '10px',
    };

    const tagStyle = {
      fontSize: '16px',
      fontWeight: 600,
    };

    const widgetBoxStyle = {
      width: '100%',
      borderTop: '2px dashed',
      borderBottom: '2px dashed',
      marginTop: '70px',
      marginBottom: '70px',
      position: 'relative',
    };

    const widgetBoxLoadStyle = {
      width: '100%',
      height: '450px',
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundColor: '#fff',
      zIndex: 10,
    };

    if (!showWidgetComponentLoad) {
      widgetBoxLoadStyle.display = 'none';
    }

    const iconColorPicker = <ChromePicker disableAlpha color={widget.settings.main_color} onChange={(color) => this.changeWidgetColor(color, 'main_color')} />;
    const buttonColorPicker = <ChromePicker disableAlpha color={widget.settings.button_color} onChange={(color) => this.changeWidgetColor(color, 'button_color')} />;
    const buttonGradientColorStartPicker = <ChromePicker disableAlpha color={widget.settings.button_gradient_color_start} onChange={(color) => this.changeWidgetColor(color, 'button_gradient_color_start')} />;
    const buttonGradientColorEndPicker = <ChromePicker disableAlpha color={widget.settings.button_gradient_color_end} onChange={(color) => this.changeWidgetColor(color, 'button_gradient_color_end')} />;
    const buttonHoverColorPicker = <ChromePicker disableAlpha color={widget.settings.button_hover_color} onChange={(color) => this.changeWidgetColor(color, 'button_hover_color')} />;
    const buyButtonColorPicker = <ChromePicker disableAlpha color={widget.settings.buy_button_color} onChange={(color) => this.changeWidgetColor(color, 'buy_button_color')} />;
    const buyButtonFontColorPicker = <ChromePicker disableAlpha color={widget.settings.buy_button_font_color} onChange={(color) => this.changeWidgetColor(color, 'buy_button_font_color')} />;
    const backgroundColorPicker = <ChromePicker disableAlpha color={widget.settings.background_color} onChange={(color) => this.changeWidgetColor(color, 'background_color')} />;
    const mainFontColorPicker = <ChromePicker disableAlpha color={widget.settings.main_font_color} onChange={(color) => this.changeWidgetColor(color, 'main_font_color')} />;
    const againstMainColorFontColorPicker = <ChromePicker disableAlpha color={widget.settings.against_main_color_font_color} onChange={(color) => this.changeWidgetColor(color, 'against_main_color_font_color')} />;
    const buttonFontColorPicker = <ChromePicker disableAlpha color={widget.settings.button_font_color} onChange={(color) => this.changeWidgetColor(color, 'button_font_color')} />;
    const checkboxDotColorPicker = <ChromePicker disableAlpha color={widget.settings.checkbox_dot_color} onChange={(color) => this.changeWidgetColor(color, 'checkbox_dot_color')} />;
    const allocationBestColorPicker = <ChromePicker disableAlpha color={widget.settings.allocation_best} onChange={(color) => this.changeWidgetColor(color, 'allocation_best')} />;
    const allocationSafeColorPicker = <ChromePicker disableAlpha color={widget.settings.allocation_safe} onChange={(color) => this.changeWidgetColor(color, 'allocation_safe')} />;
    const allocationRecommendColorPicker = <ChromePicker disableAlpha color={widget.settings.allocation_recommend} onChange={(color) => this.changeWidgetColor(color, 'allocation_recommend')} />;
    const allocationPriceColorPicker = <ChromePicker disableAlpha color={widget.settings.allocation_price} onChange={(color) => this.changeWidgetColor(color, 'allocation_price')} />;

    let widthMask;
    if (widget.settings.widthType.label === 'px') {
      widthMask = '9999';
    } else if (widget.settings.widthType.label === '%') {
      widthMask = '999';
    }

    const { showModalInfo } = this.context;

    return (
      <>
        {goToWidgetsList ? (
          <Redirect to="/widgets" />
        ) : (
          <LoadingBanner loadingFlag={loading}>
            {(widgetNotFound || widgetLoadingError) ? (
              <div style={{ textAlign: 'center', padding: '50px 0' }}>
                <h2>{widgetNotFound ? 'Данного виджета не существует' : widgetLoadingError}</h2>
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ fontSize: '60px', color: 'lightgrey' }} />
              </div>
            ) : (
              <div>
                <div className="position-relative">
                  <h1 className="page_title d-inline-block">Редактирование виджета</h1>
                  {activeTab === '4' || activeTab === '5' || activeTab === '7' ? (
                    <div className="page_header__control mb-2 ml-1 mb-md-0 d-inline-flex">
                      <Button colorType="grayBordered" type="border" width="auto" onClick={this.downloadReport}>
                        <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
                        Скачать в Excel
                      </Button>
                      <Button colorType="grayBordered" type="border" width="auto" onClick={this.customizeTable}>
                        <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
                        Настроить таблицу
                      </Button>
                    </div>
                  ) : null}
                  <div className="page_header__control page_header__control-custom mb-2 mb-md-0">
                    <div className="dropdown_absolute ml-1">
                      <div className={`dropdown_block js-dropdown_block mr-1 ${dropDown}`}>
                        <OutsideClickHandler
                          onOutsideClick={this.dropDownBlockClose}
                        >
                          <div onClick={this.dropDownBlock} className="dropdown_block__target">Действие с виджетом</div>
                          <ul className="dropdown_block__list js-dropdown_block__list">
                            <li><a href="#" onClick={this.copyWidget}>Скопировать</a></li>
                          </ul>
                        </OutsideClickHandler>
                      </div>
                    </div>
                  </div>
                  <div>
                    <LinkBreadcrumbs onClick={(e) => { e.preventDefault(); history.push('/widgets'); }}>К списку виджетов</LinkBreadcrumbs>
                  </div>
                  {widget.id ? (
                    <div className="policy_info">
                      <div>Виджет №{widget.id} от {format(parseISO(widget.created_at), 'dd.MM.yyyy')}</div>
                      <div>Автор: <a onClick={(e) => this.goToAuthor(e, widget.agent.id)} className="dotted_link" href={`/agent/${widget.agent.id}`}>{widget.agent.last_name} {widget.agent.first_name} {widget.agent.middle_name}</a></div>
                      <div>Дата последнего изменения: {format(parseISO(widget.settings.updated_at), 'dd.MM.yyyy')}</div>
                      <div>Размещен:&nbsp;
                        {(widget.widget_urls.length > 0 && widget.widget_urls.length < 5) ? widget.widget_urls.map((url, index) => {
                          if (index > 0) return <span key={url.id}>, <a className="dotted_link" target="_blank" rel="noreferrer" href={`http://${url.host}`}>{url.host}</a></span>;
                          return <span key={url.id}><a className="dotted_link" target="_blank" rel="noreferrer" href={`http://${url.host}`}>{url.host}</a></span>;
                        }) : null}
                        {widget.widget_urls.length >= 5
                          ? <span className="dotted_link" onClick={this.showUrls}>{showUrls ? <>Скрыть<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" /></> : <>Показать<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" /></>}</span>
                          : null}
                        {widget.widget_urls.length === 0 && '-'}
                        {widget.widget_urls.length > 0 && (
                          <Collapse in={showUrls}>
                            <div>
                              {widget.widget_urls.map((url, index) => {
                                if (index > 0) return <span key={url.id}>, <a className="dotted_link" target="_blank" rel="noreferrer" href={`http://${url.host}`}>{url.host}</a></span>;
                                return <span key={url.id}><a className="dotted_link" target="_blank" rel="noreferrer" href={`http://${url.host}`}>{url.host}</a></span>;
                              })}
                            </div>
                          </Collapse>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <TabNavList>
                    <TabNavItem
                      active={activeTab === '1'}
                      onClick={() => { this.toggle('1'); }}
                      text="Основные настройки"
                    />
                    <TabNavItem
                      active={activeTab === '2'}
                      onClick={() => { this.toggle('2'); }}
                      text="Сообщения и подсказки"
                    />
                    <TabNavItem
                      active={activeTab === '3'}
                      onClick={() => { this.toggle('3'); }}
                      text="Статистика"
                    />
                    <TabNavItem
                      active={activeTab === '4'}
                      onClick={() => { this.toggle('4'); }}
                      text="Лиды"
                    />
                    {(widget.policy_type.code === 'osago'
                      || widget.policy_type.code === 'dmsStudent'
                      || widget.policy_type.code === 'mortgage'
                      || widget.policy_type.code === 'kbm'
                      || widget.policy_type.code === 'property') ? (
                        <TabNavItem
                          active={activeTab === '5'}
                          onClick={() => { this.toggle('5'); }}
                          text="Оформленные договоры"
                        />
                      ) : null}
                    <TabNavItem
                      active={activeTab === '6'}
                      onClick={() => { this.toggle('6'); }}
                      text="Интеграция с системами аналитики"
                    />
                    <TabNavItem
                      active={activeTab === '7'}
                      onClick={() => { this.toggle('7'); }}
                      text="Интеграция с CRM"
                    />
                    {userInfo.role === 'admin' || userInfo.role === 'superadmin' ? (
                      <TabNavItem
                        active={activeTab === '9'}
                        onClick={() => { this.toggle('9'); }}
                        text="Интеграция с CRM (админ)"
                      />
                    ) : null}
                    {widget.policy_type.code !== 'dkp' && widget.policy_type.code !== 'kbm' && widget.policy_type.code !== 'property' && widget.policy_type.code !== 'dmsStudent' ? (
                      <TabNavItem
                        active={activeTab === '8'}
                        onClick={() => { this.toggle('8'); }}
                        text="Страховые компании"
                      />
                    ) : null}
                    {widget.policy_type.code === 'mortgage' ? (
                      <TabNavItem
                        active={activeTab === '10'}
                        onClick={() => { this.toggle('10'); }}
                        text="Акции"
                      />
                    ) : null}
                  </TabNavList>
                  <TabContent animation="false" activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div className="card">
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg-4">
                              <label htmlFor="widgetName">Наименование</label>
                              <input
                                value={widget.name}
                                onChange={this.changeWidgetName}
                                onBlur={() => { this.updateWidget(); showModalInfo('Изменения сохранены'); }}
                                name="widgetName"
                                type="text"
                                className="form-control"
                                id="widgetName"
                              />
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor="carYear">Тип</label>
                              <Select
                                classNamePrefix="react-select"
                                inputId="widgetType"
                                className="form-control-custom"
                                name="widgetType"
                                placeholder="Тип виджета"
                                noOptionsMessage={() => 'Не найдено'}
                                value={policeType}
                                styles={customStyles}
                                onChange={this.handleChangeWidgetType}
                                options={policeTypesList}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-3 col-xl-2">
                              <label htmlFor="widgetWidthFull">Ширина фиксированная</label>
                              <div className="form-check custom-checkbox" style={{ top: '12%', minHeight: '38px' }}>
                                <input onChange={this.toggleFlag} checked={widget.settings.full_width} name="full_width" className="form-check-input checkbox-styled" type="checkbox" id="full_width" />
                                <label className="form-check-label" htmlFor="full_width">
                                  100%
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-2" hidden={widget.settings.full_width}>
                              <label htmlFor="widgetWidth">Ширина произвольная</label>
                              <InputMask
                                onBlur={this.validWidgetWidth}
                                className="form-control"
                                disabled={widget.settings.full_width}
                                onChange={this.changeWidgetWidth}
                                value={widget.settings.width}
                                name="widgetWidth"
                                id="widgetWidth"
                                mask={widthMask}
                                maskChar={null}
                              />
                            </div>
                            <div className="col-xl-2 col-lg-3" hidden={widget.settings.full_width}>
                              <label htmlFor="widgetWidthType">Ед. измерения</label>
                              <Select
                                classNamePrefix="react-select"
                                onChange={this.handleChangeWidgetWidthType}
                                inputId="widgetWidthType"
                                className="form-control-custom"
                                name="widgetWidthType"
                                noOptionsMessage={() => 'Не найдено'}
                                value={widget.settings.widthType}
                                styles={customStyles}
                                options={[
                                  { value: '1', label: 'px' },
                                  { value: '2', label: '%' },
                                ]}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-5 col-xl-3">
                              <div className="color-picker-button" style={{ backgroundColor: widget.settings.main_color }} onClick={() => this.openColorPicker('Icon')} />
                              {displayColorPickerIcon ? (
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                  {iconColorPicker}
                                </div>
                              ) : null}
                              <span className="color-picker-label">Основной цвет</span>
                            </div>
                            <div className="col-lg-3 col-xl-2">
                              <div className="color-picker-button" style={{ backgroundColor: widget.settings.main_font_color }} onClick={() => this.openColorPicker('MainFont')} />
                              {displayColorPickerMainFont ? (
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                  {mainFontColorPicker}
                                </div>
                              ) : null}
                              <span className="color-picker-label">Цвет основного текста</span>
                            </div>
                            <div className="col-lg-3 col-xl-2">
                              <div className="color-picker-button" style={{ backgroundColor: widget.settings.checkbox_dot_color }} onClick={() => this.openColorPicker('CheckboxDot')} />
                              {displayColorPickerCheckboxDot ? (
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                  {checkboxDotColorPicker}
                                </div>
                              ) : null}
                              <span className="color-picker-label">Цвет чекбокса</span>
                            </div>
                            <div className="col-lg-3 col-xl-3">
                              <div className="color-picker-button" style={{ backgroundColor: widget.settings.against_main_color_font_color }} onClick={() => this.openColorPicker('AgainstMainColorFont')} />
                              {displayColorPickerAgainstMainColorFont ? (
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                  {againstMainColorFontColorPicker}
                                </div>
                              ) : null}
                              <span className="color-picker-label">Цвет текста на фоне основного цвета</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-3 col-xl-3">
                              <div className="form-check custom-checkbox" style={{ top: '12%', minHeight: '38px' }}>
                                <input onChange={this.toggleFlag} checked={widget.settings.button_gradient_active} name="background_transparent" className="form-check-input checkbox-styled" type="checkbox" id="button_gradient_active" />
                                <label className="form-check-label" htmlFor="button_gradient_active">Градиент для кнопок</label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            {widget.settings.button_gradient_active ? (
                              <>
                                <div className="col-lg-5 col-xl-3">
                                  <div className="color-picker-button" style={{ backgroundColor: widget.settings.button_gradient_color_start }} onClick={() => this.openColorPicker('ButtonGradientColorStart')} />
                                  {displayColorPickerButtonGradientColorStart ? (
                                    <div className="color-picker-popover">
                                      <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                      {buttonGradientColorStartPicker}
                                    </div>
                                  ) : null}
                                  <span className="color-picker-label">Цвет #1 основных кнопок</span>
                                </div>
                                <div className="col-lg-5 col-xl-3">
                                  <div className="color-picker-button" style={{ backgroundColor: widget.settings.button_gradient_color_end }} onClick={() => this.openColorPicker('ButtonGradientColorEnd')} />
                                  {displayColorPickerButtonGradientColorEnd ? (
                                    <div className="color-picker-popover">
                                      <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                      {buttonGradientColorEndPicker}
                                    </div>
                                  ) : null}
                                  <span className="color-picker-label">Цвет #2 основных кнопок</span>
                                </div>
                              </>
                            ) : (
                              <div className="col-lg-5 col-xl-3">
                                <div className="color-picker-button" style={{ backgroundColor: widget.settings.button_color }} onClick={() => this.openColorPicker('Button')} />
                                {displayColorPickerButton ? (
                                  <div className="color-picker-popover">
                                    <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                    {buttonColorPicker}
                                  </div>
                                ) : null}
                                <span className="color-picker-label">Цвет основных кнопок</span>
                              </div>
                            )}
                            <div className="col-lg-3 col-xl-2">
                              <div className="color-picker-button" style={{ backgroundColor: widget.settings.button_font_color }} onClick={() => this.openColorPicker('ButtonFont')} />
                              {displayColorPickerButtonFont ? (
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                  {buttonFontColorPicker}
                                </div>
                              ) : null}
                              <span className="color-picker-label">Цвет текста кнопок</span>
                            </div>
                            <div className="col-lg-5 col-xl-4">
                              <div className="color-picker-button" style={{ backgroundColor: widget.settings.button_hover_color }} onClick={() => this.openColorPicker('ButtonHover')} />
                              {displayColorPickerButtonHover ? (
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                  {buttonHoverColorPicker}
                                </div>
                              ) : null}
                              <span className="color-picker-label">Цвет перелива кнопок при загрузке</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-5 col-xl-3">
                              <div className="color-picker-button" style={{ backgroundColor: widget.settings.buy_button_color }} onClick={() => this.openColorPicker('BuyButton')} />
                              {displayColorPickerBuyButton ? (
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                  {buyButtonColorPicker}
                                </div>
                              ) : null}
                              <span className="color-picker-label">Цвет кнопок «Выбрать» и «Оплатить»</span>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                              <div className="color-picker-button" style={{ backgroundColor: widget.settings.buy_button_font_color }} onClick={() => this.openColorPicker('BuyButtonFont')} />
                              {displayColorPickerBuyButtonFont ? (
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                  {buyButtonFontColorPicker}
                                </div>
                              ) : null}
                              <span className="color-picker-label">Цвет текста кнопок «Выбрать» и «Оплатить»</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-5 col-xl-3">
                              <div className="color-picker-button" style={{ backgroundColor: widget.settings.allocation_best }} onClick={() => this.openColorPicker('AllocationBest')} />
                              {displayColorPickerAllocationBest ? (
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                  {allocationBestColorPicker}
                                </div>
                              ) : null}
                              <span className="color-picker-label">Цвет «Лучший тариф»</span>
                            </div>
                            <div className="col-lg-3 col-xl-2">
                              <div className="color-picker-button" style={{ backgroundColor: widget.settings.allocation_safe }} onClick={() => this.openColorPicker('AllocationSafe')} />
                              {displayColorPickerAllocationSafe ? (
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                  {allocationSafeColorPicker}
                                </div>
                              ) : null}
                              <span className="color-picker-label">Цвет «Надежная страховая»</span>
                            </div>
                            <div className="col-lg-3 col-xl-2">
                              <div className="color-picker-button" style={{ backgroundColor: widget.settings.allocation_recommend }} onClick={() => this.openColorPicker('AllocationRecommend')} />
                              {displayColorPickerAllocationRecommend ? (
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                  {allocationRecommendColorPicker}
                                </div>
                              ) : null}
                              <span className="color-picker-label">Цвет «Рекомендуем»</span>
                            </div>
                            <div className="col-lg-3 col-xl-3">
                              <div className="color-picker-button" style={{ backgroundColor: widget.settings.allocation_price }} onClick={() => this.openColorPicker('AllocationPrice')} />
                              {displayColorPickerAllocationPrice ? (
                                <div className="color-picker-popover">
                                  <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                  {allocationPriceColorPicker}
                                </div>
                              ) : null}
                              <span className="color-picker-label">Цвет «Такая цена только у нас»</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-3 col-xl-3">
                              <div className="form-check custom-checkbox" style={{ top: '12%', minHeight: '38px' }}>
                                <input onChange={this.toggleFlag} checked={widget.settings.background_transparent} name="background_transparent" className="form-check-input checkbox-styled" type="checkbox" id="background_transparent" />
                                <label className="form-check-label" htmlFor="background_transparent">Прозрачный фон</label>
                              </div>
                            </div>
                            {!widget.settings.background_transparent ? (
                              <div className="col-lg-5 col-xl-4">
                                <div className="color-picker-button" style={{ backgroundColor: widget.settings.background_color }} onClick={() => this.openColorPicker('Background')} />
                                {displayColorPickerBackground ? (
                                  <div className="color-picker-popover">
                                    <div className="color-picker-cover" onClick={this.closeColorPicker} />
                                    {backgroundColorPicker}
                                  </div>
                                ) : null}
                                <span className="color-picker-label">Цвет фона</span>
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-xl-5">
                              <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
                                <input onChange={this.toggleFlag} checked={widget.settings.show_container_shadow} name="show_container_shadow" className="form-check-input checkbox-styled" type="checkbox" id="show_container_shadow" />
                                <label className="form-check-label" htmlFor="show_container_shadow">Отображать тень у виджета</label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-xl-5">
                              <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
                                <input onChange={this.toggleFlag} checked={widget.settings.show_badges} name="show_badges" className="form-check-input checkbox-styled" type="checkbox" id="show_badges" />
                                <label className="form-check-label" htmlFor="show_badges">Отображать бейджи с краткой информацией</label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-xl-5">
                              <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
                                <input onChange={this.toggleFlag} checked={widget.settings.button_border_radius} name="button_border_radius" className="form-check-input checkbox-styled" type="checkbox" id="button_border_radius" />
                                <label className="form-check-label" htmlFor="button_border_radius">Скругленные углы у кнопок</label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-xl-5">
                              <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
                                <input onChange={this.toggleFlag} checked={widget.settings.tag_icon} name="tag_icon" className="form-check-input checkbox-styled" type="checkbox" id="tag_icon" />
                                <label className="form-check-label" htmlFor="tag_icon">Теги с иконками</label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6 col-xl-5">
                              <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
                                <input onChange={this.toggleFlag} checked={widget.settings.header_visible} name="header_visible" className="form-check-input checkbox-styled" type="checkbox" id="header_visible" />
                                <label className="form-check-label" htmlFor="header_visible">Показывать шапку</label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-6">
                              <label className="img-upload mr-3" htmlFor="image">
                                <span>Загрузить изображение</span>
                                <span className="plus">&#43;</span>
                              </label>
                              <input
                                name="image"
                                id="image"
                                type="file"
                                className="custom-input-file btn"
                                onChange={(e) => this.uploadImage(e.target.files)}
                              />
                            </div>
                          </div>
                          {widget.settings.img ? (
                            <div className="form-group row">
                              <div className="col-lg-6">
                                <button type="button" className="btn btn-danger mr-3" onClick={this.deleteImage}>Удалить изображение</button>
                              </div>
                            </div>
                          ) : null}
                          <div className="form-group row">
                            <div className="col-lg-3 col-xl-3">
                              <label htmlFor="widgetFont">Шрифт</label>
                              <Select
                                classNamePrefix="react-select"
                                onChange={this.handleChangeWidgetFont}
                                inputId="widgetFont"
                                className="form-control-custom"
                                name="widgetFont"
                                noOptionsMessage={() => 'Не найдено'}
                                value={selectedFont}
                                styles={customStyles}
                                options={fontSelectList}
                              />
                            </div>
                          </div>
                          {widget.policy_type.code !== 'dkp' && widget.policy_type.code !== 'kbm' && widget.policy_type.code !== 'property' && widget.policy_type.code !== 'dmsStudent' ? (
                            <div className="form-group row">
                              <div className="col-lg-4 col-xl-3">
                                <label htmlFor="widgetLidogenerateType">Способ лидогенерации</label>
                                <Select
                                  classNamePrefix="react-select"
                                  onChange={this.handleChangeWidgetLidogenerateType}
                                  inputId="widgetLidogenerateType"
                                  className="form-control-custom"
                                  name="widgetLidogenerateType"
                                  noOptionsMessage={() => 'Не найдено'}
                                  value={lidogenerateType}
                                  styles={customStyles}
                                  options={[
                                    { value: 'before', label: 'До вывода результатов' },
                                    { value: 'after', label: 'После выдачи результатов' },
                                    { value: 'none', label: 'Отключить проверку телефона' },
                                  ]}
                                />
                              </div>
                            </div>
                          ) : null}
                          {widget.policy_type.code === 'osago' || widget.policy_type.code === 'dmsStudent' ? (
                            <div className="form-group row">
                              <div className="col-lg-4 col-xl-3">
                                <label htmlFor="widgetPaymenRedirectLink">Адрес страницы редиректа после оплаты полиса</label>
                                <InputMask
                                  onBlur={this.updateWidgetWithInfo}
                                  className="form-control"
                                  onChange={this.changePaymentRedirectLink}
                                  value={widget.settings.payment_redirect_url}
                                  name="widgetPaymenRedirectLink"
                                  id="widgetPaymenRedirectLink"
                                  maskChar={null}
                                  placeholder={defaultPaymentRedirectLink}
                                />
                              </div>
                            </div>
                          ) : null}
                          <div className="form-group row">
                            <div className="col-lg-6 col-xl-5">
                              <div className="form-check custom-checkbox" style={{ minHeight: '38px' }}>
                                <input onChange={this.toggleFlag} checked={widget.settings.payment_new_tab} name="button_border_radius" className="form-check-input checkbox-styled" type="checkbox" id="payment_new_tab" />
                                <label className="form-check-label" htmlFor="payment_new_tab">Шлюз оплаты в новом окне</label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-10" style={{ position: 'relative' }}>
                              <label htmlFor="widgetLidogenerateType">Email адреса для рассылки сообщений с виджета</label>
                              <CreatableSelect
                                components={components}
                                inputValue={emailListInputValue}
                                isClearable
                                className="form-control-custom"
                                styles={customStyles}
                                isMulti
                                onBlur={this.emailListHandleKeyDown}
                                menuIsOpen={false}
                                onChange={this.emailListHandleChange}
                                onInputChange={this.emailListHandleInputChange}
                                onKeyDown={this.emailListHandleKeyDown}
                                placeholder="Введите email адрес"
                                value={emailListValue}
                              />
                              <span style={{ position: 'absolute', color: 'red' }}>{emailListError}</span>
                            </div>
                          </div>
                          {(widget.policy_type.code === 'osago' || widget.policy_type.code === 'mortgage') && (userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                            <WidgetCallbackSettings
                              widget={widget}
                              tab={activeTab}
                              toggleFlag={this.toggleFlag}
                            />
                          ) : null}
                          {widget.policy_type.code === 'kbm' ? (
                            <div className="form-group row">
                              <div className="col-lg-4">
                                <label htmlFor="widgetOsagoLandingPage">Посадочная страница с калькулятором ОСАГО</label>
                                <input
                                  value={widget.settings.osago_landing_page}
                                  onChange={this.changeWidgetOsagoLandingPage}
                                  onBlur={() => {
                                    this.updateWidget();
                                    this.showModalInfo('Изменения сохранены');
                                  }}
                                  name="widgetOsagoLandingPage"
                                  type="text"
                                  className="form-control"
                                  id="widgetOsagoLandingPage"
                                />
                                <span style={{ position: 'absolute', color: 'red' }}>{emailListError}</span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="card">
                        <div className="card-body">
                          {widget.policy_type.code !== 'mortgage' ? (
                            <div className="form-group row">
                              <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 col-12">
                                <label htmlFor="baseHelpText">Заголовок на начальном экране</label>
                                <textarea
                                  name="baseHeader"
                                  id="baseHeader"
                                  className="form-control"
                                  placeholder=""
                                  style={{ resize: 'none' }}
                                  onChange={(e) => this.handleChangeHelpText(e, 'baseHeader')}
                                  onBlur={() => {
                                    this.updateWidgetWithInfo();
                                  }}
                                  value={widget.help_texts.baseHeader}
                                />
                              </div>
                            </div>
                          ) : null}
                          <div className="form-group row">
                            <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 col-12">
                              <label htmlFor="header">Заголовок H1</label>
                              <textarea
                                name="header"
                                id="header"
                                className="form-control"
                                placeholder=""
                                style={{ resize: 'none' }}
                                onChange={(e) => this.handleChangeHelpText(e, 'header')}
                                onBlur={() => {
                                  this.updateWidgetWithInfo();
                                }}
                                value={widget.help_texts.header}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 col-12">
                              <label htmlFor="baseHelpText">Сообщение на начальном экране</label>
                              <textarea
                                name="baseHelpText"
                                id="baseHelpText"
                                className="form-control"
                                placeholder="Сообщение на начальном экране..."
                                style={{ resize: 'none' }}
                                onChange={(e) => this.handleChangeHelpText(e, 'base')}
                                onBlur={() => {
                                  this.updateWidgetWithInfo();
                                }}
                                value={widget.help_texts.base}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 col-12">
                              <label htmlFor="step1HelpText">Сообщение на шаге {widget.policy_type.code === 'mortgage' ? '«Страхователь»' : '№1'}</label>
                              <textarea
                                name="step1HelpText"
                                id="step1HelpText"
                                className="form-control"
                                placeholder=""
                                style={{ resize: 'none' }}
                                onChange={(e) => this.handleChangeHelpText(e, 'one')}
                                onBlur={() => {
                                  this.updateWidgetWithInfo();
                                }}
                                value={widget.help_texts.steps.one}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 col-12">
                              <label htmlFor="step2HelpText">Сообщение на шаге {widget.policy_type.code === 'mortgage' ? '«Данные об ипотеке»' : '№2'}</label>
                              <textarea
                                name="step2HelpText"
                                id="step2HelpText"
                                className="form-control"
                                placeholder=""
                                style={{ resize: 'none' }}
                                onChange={(e) => this.handleChangeHelpText(e, 'two')}
                                onBlur={() => {
                                  this.updateWidgetWithInfo();
                                }}
                                value={widget.help_texts.steps.two}
                              />
                            </div>
                          </div>
                          {widget.policy_type.code === 'osago' || widget.policy_type.code === 'dkp' || widget.policy_type.code === 'dmsStudent'
                          || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
                            <div className="form-group row">
                              <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 col-12">
                                <label htmlFor="step3HelpText">Сообщение на шаге {widget.policy_type.code === 'mortgage' ? '«Данные о полисе»' : '№3'}</label>
                                <textarea
                                  name="step3HelpText"
                                  id="step3HelpText"
                                  className="form-control"
                                  placeholder=""
                                  style={{ resize: 'none' }}
                                  onChange={(e) => this.handleChangeHelpText(e, 'three')}
                                  onBlur={() => {
                                    this.updateWidgetWithInfo();
                                  }}
                                  value={widget.help_texts.steps.three}
                                />
                              </div>
                            </div>
                            ) : null}
                          {widget.policy_type.code === 'osago' || widget.policy_type.code === 'dkp'
                          || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
                            <div className="form-group row">
                              <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 col-12">
                                <label htmlFor="step4HelpText">Сообщение на шаге {widget.policy_type.code === 'mortgage' ? '«Контакты»' : '№4'}</label>
                                <textarea
                                  name="step4HelpText"
                                  id="step4HelpText"
                                  className="form-control"
                                  placeholder=""
                                  style={{ resize: 'none' }}
                                  onChange={(e) => this.handleChangeHelpText(e, 'four')}
                                  onBlur={() => {
                                    this.updateWidgetWithInfo();
                                  }}
                                  value={widget.help_texts.steps.four}
                                />
                              </div>
                            </div>
                            ) : null}
                          {widget.policy_type.code === 'osago'
                          || widget.policy_type.code === 'mortgage' || widget.policy_type.code === 'mortgageLife' ? (
                            <div className="form-group row">
                              <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 col-12">
                                <label htmlFor="step5HelpText">Сообщение на шаге {widget.policy_type.code === 'mortgage' ? '«Данные о жизни и здоровье»' : '№5'}</label>
                                <textarea
                                  name="step5HelpText"
                                  id="step5HelpText"
                                  className="form-control"
                                  placeholder=""
                                  style={{ resize: 'none' }}
                                  onChange={(e) => this.handleChangeHelpText(e, 'five')}
                                  onBlur={() => {
                                    this.updateWidgetWithInfo();
                                  }}
                                  value={widget.help_texts.steps.five}
                                />
                              </div>
                            </div>
                            ) : null}
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      {activeTab === '3' && widget.id !== 0 ? (
                        <WidgetAnalytics history={history} tab={activeTab} widget={widget} setTour={setTour} setDataLoadFunctions={setDataLoadFunctions} />
                      ) : null }
                    </TabPane>
                    <TabPane tabId="4">
                      {activeTab === '4' && widget.id !== 0 ? (
                        <WidgetPolicesList ref={activeTab === '4' ? this.child : undefined} history={history} tab={activeTab} widgetId={widget.id} type="request" widgetType={widget.policy_type.code} />
                      ) : null }
                    </TabPane>
                    {(widget.policy_type.code === 'osago'
                      || widget.policy_type.code === 'dmsStudent'
                      || widget.policy_type.code === 'mortgage'
                      || widget.policy_type.code === 'kbm'
                      || widget.policy_type.code === 'property') ? (
                        <TabPane tabId="5">
                          {activeTab === '5' && widget.id !== 0 ? (
                            <WidgetPolicesList ref={activeTab === '5' ? this.child : undefined} history={history} tab={activeTab} widgetId={widget.id} type="policy" widgetType={widget.policy_type.code} />
                          ) : null }
                        </TabPane>
                      ) : null}
                    <TabPane tabId="6">
                      {activeTab === '6' && widget.id !== 0 ? (
                        <WidgetAnalyticsIntegrationList
                          toggleFlag={this.toggleFlag}
                          changeWidgetAnalyticsIntegrationId={this.changeWidgetAnalyticsIntegrationId}
                          updateWidgetWithInfo={this.updateWidgetWithInfo}
                          widget={widget}
                          history={history}
                          tab={activeTab}
                        />
                      ) : null }
                    </TabPane>
                    {userInfo.role === 'admin' || userInfo.role === 'superadmin' ? (
                      <TabPane tabId="9">
                        {activeTab === '9' && widget.id !== 0 ? (
                          <WidgetCRMList integrationType="admin" changeCRMIntegrationActive={this.changeCRMIntegrationActive} widget={widget} showModalInfo={this.showModalInfo} ref={activeTab === '9' ? this.child : undefined} history={history} tab={activeTab} />
                        ) : null }
                      </TabPane>
                    ) : null}
                    <TabPane tabId="7">
                      {activeTab === '7' && widget.id !== 0 ? (
                        <WidgetCRMList integrationType="agent" changeCRMIntegrationActive={this.changeCRMIntegrationActive} widget={widget} ref={activeTab === '7' ? this.child : undefined} history={history} tab={activeTab} />
                      ) : null }
                    </TabPane>
                    <TabPane tabId="8">
                      {activeTab === '8' && widget.id !== 0 ? (
                        <WidgetInsurancesSettings
                          widget={widget}
                          tab={activeTab}
                          toggleFlag={this.toggleFlag}
                          changeCommissionLimit={this.changeCommissionLimit}
                          updateWidgetWithInfo={this.updateWidgetWithInfo}
                        />
                      ) : null }
                    </TabPane>
                    <TabPane tabId="10">
                      {widget.policy_type.code === 'mortgage' && activeTab === '10' && widget.id !== 0 ? (
                        <WidgetStocks
                          widget={widget}
                          tab={activeTab}
                        />
                      ) : null }
                    </TabPane>
                  </TabContent>
                  {activeTab === '1' || activeTab === '2' ? (
                    <>
                      <div style={widgetBoxStyle}>
                        {showWidgetComponent ? (
                          <>
                            <div style={widgetBoxLoadStyle} />
                            {widget.policy_type.code === 'osago' ? (
                              <polis-online-widget-osago name="polis-online-widget-osago" data-api-key={widget.apiKey} data-widget-id={widget.id} style={{ width: '100%' }} data-widget-in-service="true" />
                            ) : null}
                            {widget.policy_type.code === 'greenCard' ? (
                              <polis-online-widget-green-card name="polis-online-widget-green-card" data-api-key={widget.apiKey} data-widget-id={widget.id} style={{ width: '100%' }} data-widget-in-service="true" />
                            ) : null}
                            {widget.policy_type.code === 'dkp' ? (
                              <polis-online-widget-dkp name="polis-online-widget-dkp" data-api-key={widget.apiKey} data-widget-id={widget.id} style={{ width: '100%' }} data-widget-in-service="true" />
                            ) : null}
                            {widget.policy_type.code === 'kbm' ? (
                              <polis-online-widget-kbm name="polis-online-widget-kbm" data-api-key={widget.apiKey} data-widget-id={widget.id} style={{ width: '100%' }} data-widget-in-service="true" />
                            ) : null}
                            {widget.policy_type.code === 'property' ? (
                              <polis-online-widget-property name="polis-online-widget-property" data-api-key={widget.apiKey} data-widget-id={widget.id} style={{ width: '100%' }} data-widget-in-service="true" />
                            ) : null}
                            {widget.policy_type.code === 'dmsStudent' ? (
                              <polis-online-widget-dms name="polis-online-widget-dms" data-api-key={widget.apiKey} data-widget-id={widget.id} style={{ width: '100%' }} data-widget-in-service="true" />
                            ) : null}
                            {widget.policy_type.code === 'mortgage' ? (
                              <polis-online-widget-mortgage name="polis-online-widget-mortgage" data-api-key={widget.apiKey} data-widget-id={widget.id} style={{ width: '100%' }} data-widget-in-service="true" />
                            ) : null}
                            {widget.policy_type.code === 'mortgageLife' ? (
                              <polis-online-widget-mortgage-life name="polis-online-widget-mortgage-life" data-api-key={widget.apiKey} data-widget-id={widget.id} style={{ width: '100%' }} data-widget-in-service="true" />
                            ) : null}
                          </>
                        ) : null}
                      </div>
                      <p />
                      <div className="row form-group">
                        <div className="col-lg-12">
                          <p style={tagStyle}>{'<BODY>'}</p>
                          <p>Вставьте данный код на ваш сайт в тело тега body:&nbsp;<button type="button" className="btn btn-secondary widget-copy-btn" onClick={this.copyWidgetLink}>скопировать</button></p>
                          <p style={codeStyle} id="widgetLinkText">
                            {widget.policy_type.code === 'osago' ? (
                              `<polis-online-widget-osago name="polis-online-widget-osago" data-api-key="${widget.apiKey}" data-widget-id=${widget.id}></polis-online-widget-osago>`
                            ) : null}
                            {widget.policy_type.code === 'greenCard' ? (
                              `<polis-online-widget-green-card name="polis-online-widget-green-card" data-api-key="${widget.apiKey}" data-widget-id=${widget.id}></polis-online-widget-green-card>`
                            ) : null}
                            {widget.policy_type.code === 'dkp' ? (
                              `<polis-online-widget-dkp name="polis-online-widget-dkp" data-api-key="${widget.apiKey}" data-widget-id=${widget.id}></polis-online-widget-dkp>`
                            ) : null}
                            {widget.policy_type.code === 'kbm' ? (
                              `<polis-online-widget-kbm name="polis-online-widget-kbm" data-api-key="${widget.apiKey}" data-widget-id=${widget.id}></polis-online-widget-kbm>`
                            ) : null}
                            {widget.policy_type.code === 'property' ? (
                              `<polis-online-widget-property name="polis-online-widget-property" data-api-key="${widget.apiKey}" data-widget-id=${widget.id}></polis-online-widget-property>`
                            ) : null}
                            {widget.policy_type.code === 'dmsStudent' ? (
                              `<polis-online-widget-dms name="polis-online-widget-dms" data-api-key="${widget.apiKey}" data-widget-id=${widget.id}></polis-online-widget-dms>`
                            ) : null}
                            {widget.policy_type.code === 'mortgage' ? (
                              `<polis-online-widget-mortgage name="polis-online-widget-mortgage" data-api-key="${widget.apiKey}" data-widget-id=${widget.id}></polis-online-widget-mortgage>`
                            ) : null}
                            {widget.policy_type.code === 'mortgageLife' ? (
                              `<polis-online-widget-mortgage-life name="polis-online-widget-mortgage=life" data-api-key="${widget.apiKey}" data-widget-id=${widget.id}></polis-online-widget-mortgage-life>`
                            ) : null}
                            <br />
                            {widget.policy_type.code === 'osago' ? (
                              `<script src='https://${window.location.host.split('.')[0]}.polis.online/widget_v2.min.js'></script>`
                            ) : null}
                            {widget.policy_type.code === 'greenCard' ? (
                              `<script src='https://${window.location.host.split('.')[0]}.polis.online/widget_gk_v2.min.js'></script>`
                            ) : null}
                            {widget.policy_type.code === 'dkp' ? (
                              `<script src='https://${window.location.host.split('.')[0]}.polis.online/widget_dkp_v2.min.js'></script>`
                            ) : null}
                            {widget.policy_type.code === 'kbm' ? (
                              `<script src='https://${window.location.host.split('.')[0]}.polis.online/widget_kbm_v2.min.js'></script>`
                            ) : null}
                            {widget.policy_type.code === 'property' ? (
                              `<script src='https://${window.location.host.split('.')[0]}.polis.online/widget_property_v2.min.js'></script>`
                            ) : null}
                            {widget.policy_type.code === 'dmsStudent' ? (
                              `<script src='https://${window.location.host.split('.')[0]}.polis.online/widget_dms_v2.min.js'></script>`
                            ) : null}
                            {widget.policy_type.code === 'mortgage' ? (
                              `<script src='https://${window.location.host.split('.')[0]}.polis.online/widget_mortgage_v2.min.js'></script>`
                            ) : null}
                            {widget.policy_type.code === 'mortgageLife' ? (
                              `<script src='https://${window.location.host.split('.')[0]}.polis.online/widget_mortgage_life_v2.min.js'></script>`
                            ) : null}
                          </p>
                          <p style={tagStyle}>{'</BODY>'}</p>
                        </div>
                      </div>
                      {widget.policy_type.code === 'osago' ? (
                        <div className="form-group row">
                          <div className="col-lg-12">
                            <FormNotificationColorLine>
                              Для того, чтобы пропустить начальный шаг с вводом гос. номера необходимо передать на страницу с виджетом GET-параметры carNumber или noCarNumber.<br />
                              Пример: <a className="dotted_link" rel="noopener noreferrer" target="_blank" href="https://polis.online/calc?carNumber=ААА123АА">https://polis.online/calc?carNumber=ААА123АА</a>
                            </FormNotificationColorLine>
                          </div>
                        </div>
                      ) : null}
                      <div className="form-group row">
                        <div className="col-lg-12">
                          <button type="button" className="btn btn-danger mr-3" onClick={this.toggleDeleteModalWindow}>Удалить виджет</button>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                <Modal
                  classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
                  closeIconSize={16}
                  open={openModalSure}
                  onClose={this.toggleDeleteModalWindow}
                  center
                >
                  <h4>Вы уверены?</h4>
                  <button className="btn btn-success mr-2" type="button" onClick={this.handleSubmitDeleteSure}>Да</button>
                  <button type="button" className="btn btn-secondary" onClick={this.toggleDeleteModalWindow}>Нет</button>
                </Modal>
              </div>
            )}
          </LoadingBanner>
        )}
      </>
    );
  }
}

Widget.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(Widget);
